let currencyToggle = document.querySelectorAll(".currencyToggle");
let currencyModal = document.querySelectorAll(".currencyModal")[0];
let currencyButton = document.querySelectorAll(".currencyModal__inner-submit--button")[0];
let currencyOutput = document.querySelectorAll(".gdp")[0];
if(currencyToggle){
    
    currencyToggle.forEach((toggle) => {
        toggle.onclick = function(){
            if(currencyModal.classList.contains("active")){
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

                currencyModal.classList.remove("active")
                if(document.body.classList.contains("nav-active") == true){
                    document.body.classList.remove("nav-active")
                }
            } else {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

                currencyModal.classList.add("active");
                if(document.body.classList.contains("nav-active") == false){
                    document.body.classList.add("nav-active")
                }
            }
        } 
    })
currencyButton.onclick = function(){
    
    let reqCurrency = document.querySelector('input[name="currency"]:checked').value;
    $.ajax({
        async: false,
        method: "POST",
        data:{
            currency: reqCurrency, 
        },	
        url: '/js/xhr/setCurrency.php',
            success:function(data) {
                currencyOutput.innerHTML = data;
                currencyModal.classList.remove("active")
                document.body.classList.remove("nav-active") 
                location.reload();
            },
            error: function(){
            console.log("Error")
        }
    });
}

}