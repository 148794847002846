var Shuffle = window.Shuffle;
var all = document.getElementById("allProjects");
if (all){
class ProjectShuffle {
  constructor(element) {
    this.element = element;
    this.shuffle = new Shuffle(element, {
      itemSelector: '.project-item',
      gutterWidth: 20,
      percentPosition: true,
      speed: 600,
    });
    this.addShuffleEventListeners();
    this._activeFilters = [];
    this.addFilterButtons();
    this.addSorting();
    this.addSearchFilter();
 
  }
  addShuffleEventListeners() {
    this.shuffle.on(Shuffle.EventType.LAYOUT, (data) => {
      console.log('layout. data:', data);
    });
    this.shuffle.on(Shuffle.EventType.REMOVED, (data) => {
      console.log('removed. data:', data);
    });
  }
  addFilterButtons() {
    const options = document.querySelector('.filter-options');
    if (!options) {
      return;
    }
    
    const filterButtons = Array.from(options.children);
    const onClick = this._handleFilterClick.bind(this);
    filterButtons.forEach((button) => {
      button.addEventListener('click', onClick, false);
    });
  }

  _handleFilterClick(evt) {
    const btn = evt.currentTarget;
    const isActive = btn.classList.contains('active');
    const btnGroup = btn.getAttribute('data-group');
    
    this._removeActiveClassFromChildren(btn.parentNode);
    
    let filterGroup;
    if (isActive) {
      btn.classList.remove('active');
      all.classList.add('active')
      filterGroup = Shuffle.ALL_ITEMS;
    } else {
      btn.classList.add('active');
      filterGroup = btnGroup;
    }
    if(btn.id === "allProjects"){
        this.shuffle.filter();
    }
    else{
    this.shuffle.filter(filterGroup);
  
    }
  }

  _removeActiveClassFromChildren(parent) {
    const { children } = parent;
    for (let i = children.length - 1; i >= 0; i--) {
      children[i].classList.remove('active');
    }
  }

  addSorting() {
    const buttonGroup = document.querySelector('.sort-options');
    if (!buttonGroup) {
      return;
    }
    buttonGroup.addEventListener('change', this._handleSortChange.bind(this));
  }

  _handleSortChange(evt) {
    const buttons = Array.from(evt.currentTarget.children);
    buttons.forEach((button) => {
      if (button.querySelector('input').value === evt.target.value) {
        button.classList.add('active');
      } else {
        button.classList.remove('active');
      }
    });
        const { value } = evt.target;
        function sortByDate(element) {
          return element.getAttribute('data-created');
        }
        
        function sortByTitle(element) {
          return element.getAttribute('data-title').toLowerCase();
        }
        
        if (value === 'date-created') {
          options = {
            reverse: true,
            by: sortByDate,
          };
        } else if (value === 'title') {
          options = {
            by: sortByTitle,
          };
        }
        this.shuffle.sort(options);
      }

      addSearchFilter() {
        const searchInput = document.querySelector('.js-shuffle-search');
        if (!searchInput) {
          return;
        }
        searchInput.addEventListener('keyup', this._handleSearchKeyup.bind(this));
      }

    /**
     * Filter the shuffle instance by items with a title that matches the search input.
     * @param {Event} evt Event object.
     */
      _handleSearchKeyup(evt) {
        const searchText = evt.target.value.toLowerCase();
        this.shuffle.filter((element, shuffle) => {
          // If there is a current filter applied, ignore elements that don't match it.
          if (shuffle.group !== Shuffle.ALL_ITEMS) {
            // Get the item's groups.
            const groups = JSON.parse(element.getAttribute('data-groups'));
            const isElementInCurrentGroup = groups.indexOf(shuffle.group) !== -1;
            // Only search elements in the current group
            if (!isElementInCurrentGroup) {
              return false;
            }
          }
          const titleElement = element.querySelector('.picture-item__title');
          const titleText = titleElement.textContent.toLowerCase().trim();
          return titleText.indexOf(searchText) !== -1;
        });
      }
    }
    
    window.addEventListener('load', () => { 
    const projectItems = [...document.querySelectorAll(".project-item")];
    projectItems.forEach(projectItem => {
      projectItem.addEventListener("click", function(){
        window.location.href = "/project/" + projectItem.dataset.link;
      });
    });
    window.ProjectShuffle = new ProjectShuffle(document.getElementById('grid'));
    // Literally no idea why this works but if you remove this timeout 
    //   shuffle wont load the grid until after you click a button
    //   Probably something to do with when the JS is being fired, could probably rewrite shuffle but this works for now
    // ¯\_(ツ)_/¯
    setTimeout(function(){
        document.getElementById("allProjects").click()
       }, 1);
    });
}