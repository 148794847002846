import Shipping from "./shipping";

// Create the Checkout "namespace"
/*global Spinner */
/*global Validation */
var EskimoCheckout = function() {
	
	/**
     * SpinJS config
     */
    var spinJSConfig = {
        lines: 9, // The number of lines to draw
        length: 13, // The length of each line
        width: 3, // The line thickness
        radius: 7, // The radius of the inner circle
        corners: 1, // Corner roundness (0..1)
        rotate: 30, // The rotation offset
        direction: 1, // 1: clockwise, -1: counterclockwise
        color: '#C00', // #rgb or #rrggbb or array of colors
        speed: 1, // Rounds per second
        trail: 45, // Afterglow percentage
        shadow: false, // Whether to render a shadow
        hwaccel: false, // Whether to use hardware acceleration
        className: 'spinner', // The CSS class to assign to the spinner
        zIndex: 2e9, // The z-index (defaults to 2000000000)
        top: 'auto', // Top position relative to parent in px
        left: 'auto' // Left position relative to parent in px
    };

    var spinner = new Spinner(spinJSConfig);

	var options = {
		currentSeasonForm: $('form#currentSeason'),
		newSeasonForm: $('form#newSeason')
	};

	function init()
	{
		console.log('[Checkout] initialized');
		if($('#submitTradeOrder').length){
			initTradeCart();
		}

		checkStock();

		setInterval(checkStock, 10000);
	}

	function checkStock() 
	{	
		if (window.location.pathname != '/checkout') {
			return;
		}

		$.ajax({
			method: 'GET',
			url: '/js/xhr/check-stock.php',
			dataType: 'JSON',
		}).done(function(data) {

			if (data.inStock === false) {
				window.location.href = '/shopping-bag';
				return false;
			} else {
				return true;
			}
		});
	}

	function initTradeCart()
	{
		$('#submitTradeOrder').click(function(e){
			e.preventDefault();
			if($(this).prop('disabled')){
				return;
			}
			$(this).prop('disabled', true);
			$(this).addClass('loadingGif');
			var data = [];
			if (options.newSeasonForm.length) {
				data.push(...options.newSeasonForm.serializeArray());
			}
			if (options.currentSeasonForm.length) {
				data.push(...options.currentSeasonForm.serializeArray());
			}
			submitTradeOrder(data);
		});
	}

	function submitTradeOrder(data)
	{
		let errors = document.querySelectorAll('.js-error');

		if (errors.length) {
			errors.forEach(function(error) {
				error.remove();
			});
		}

		return $.ajax({
			method: 'POST',
			url: '/js/xhr/trade/submitOrder.php',
			dataType: 'JSON',
			data: data
		}).done(function(data){

			if (data.redirect){
				window.location.href = data.redirect;
			}

		}).error(function(data) {

			var response = JSON.parse(data.responseText);

			for (var k in response.errors) {
				addError(k, response.errors[k]);
			}

			resetButton();
		});
	}

	function resetButton() {

		var button = document.getElementById('submitTradeOrder');

		if (!button) {
			return;
		}

		button.classList.remove('loadingGif');
		button.disabled = false;
	}

	function addError(selector, message) {

		var element = document.querySelector('.' + selector);

		if (element) {

			var error = document.createElement("span");
			error.classList.add('js-error', 'error');
			error.innerHTML = message;
			element.parentNode.insertBefore(error, element.nextSibling);
		}
	}

	/*
	 * Accordion
	 */
	function initAccordion($accordion) {
		_setHeights($accordion);
		_bindAccordionEvents($accordion);
	}

	function _setHeights($accordion) {
		$accordion.find('.step .content').each(function(index, el) {
			var $el    = $(el);
			var height = $el.css('max-height', '').height();

			$el.data('max-height', height);

			if($el.closest('.step').hasClass('active')) {
				$el.css('max-height', height);
			} else {
				$el.css('max-height', 0);
			}
		});
	}

	function getAddressFieldsSelector(address) {
		var fields = {
			title: 'select[name="'+address+'Title"]',
			firstName: 'input[name="'+address+'FirstName"]',
			surname: 'input[name="'+address+'Surname"]',
			houseNumber: 'input[name="'+address+'HouseNumber"]',
			street: 'input[name="'+address+'Street"]',
			address1: 'input[name="'+address+'Street"]',
			address2: 'input[name="'+address+'Address1"]',
			address3: 'input[name="'+address+'Address2"]',
			city: 'input[name="'+address+'City"]',
			county: 'input[name="'+address+'County"]',
			state: 'select[name="'+address+'StateCode"]',
			postCode: 'input[name="'+address+'Postcode"]',
			country: 'select[name="'+address+'Country"]'
		};

		return fields;
	}

	function _submitAccordionLogin($accordion, $this) {
		var $form = $this.closest('.step');

		spinner.spin($form[0]);

		// First validate what's been entered
		if(!Validation.validateForm($form)) {
			spinner.stop();
			return false;
		}

		$.ajax({
			type: "POST",
			cache: false,
			url: "/js/xhr/login.php",
			data: {
				username: $form.find('input[name="username"]').val(),
				password: $form.find('input[name="password"]').val()
			},
			success: function(response) {
				spinner.stop();

				response = $.parseJSON(response);

				if(response.success) {
					$form = $form.closest('form');

					// We've been signed in so remove the password fields used for the registration
					$form.find('.step:nth-of-type(2) input[name="password"]').closest('fieldset').remove();
					$form.find('.step:nth-of-type(2) input[name="password2"]').closest('fieldset').remove();
					$form.find('input[name="userWasLoggedIn"]').val('true');

					// Pre-pop the form fields
					$.each(response.fields, function(key, val) {
						$form.find('input[name="'+key+'"]').val(val);
						$form.find('select[name="'+key+'"]').val(val);
					});

					$form.find('.continue-and-register button').trigger('click');
				} else {
					alert('Sorry, either your username or password are incorrect.');
				}
			},
			error: function() {
				spinner.stop();
				alert('Sorry, either your username or password are incorrect.');
			}
		});
	}

	function _switchAccordionPanel($accordion, method, $this, skipValidation) {

		var $currentPanel, $newPanel;

		if(!skipValidation) {
			// First validate what's been entered
			if(!Validation.validateForm($this.closest('.step'))) {
				return false;
			}
		}

		// Valid entries so progress to next panel
		if(method === 'linearNav') {
			$currentPanel = $this.closest('.step');
			$newPanel     = $currentPanel.next();
		} else if(method === 'directNav') {
			$currentPanel = $accordion.find('.step.active');
			$newPanel     = $this.closest('.step');
		}

		var panelID = $currentPanel.attr('id');
		var panelHeight   = $newPanel.find('.content').data('max-height');

		// This wee hack is need so custom dropdowns don't get cut off (couldn't think of a better way round it, I'm so ashamed)
		$currentPanel.find('.content').css('overflow', '');

		if(!$newPanel.hasClass('active')) {
			$currentPanel.removeClass('active').find('.content').css('max-height', 0);
			$newPanel.addClass('active').find('.content').css('max-height', panelHeight);

			// Horrible hack continued
			$newPanel.find('.content').on('transitionend webkitTransitionEnd otransitionend msTransitionEnd', function() {
				_scrollTo('#'+$newPanel.attr('id'));
				$(this).css('overflow', 'visible').off('transitionend webkitTransitionEnd otransitionend msTransitionEnd');
			});
		}

		if($newPanel.hasClass('remove-login-fields')) {
			$accordion.find('.step:first-of-type input').attr('disabled', true);
		}

		if($newPanel.hasClass('check-address-prepop') && $('input[name="sameAsBilling"]').prop('checked')) {
			var billingFields  = getAddressFieldsSelector('billing');
			var deliveryFields = getAddressFieldsSelector('delivery');

			// Update delivery fields to match the billing fields
			$.each(deliveryFields, function(key, selector) {
				$accordion.find(selector).val($accordion.find(billingFields[key]).val());
			});
		}

		// if($newPanel.hasClass('get-delivery-options')) {
		// 	getDeliveryOptions($this.closest('form'));
		// }

		if($newPanel.hasClass('confirm-vat')) {
			_confirmVATForOrder($this.closest('form'));
		}

		// Build details for display in the order summary
		var checkout = {};
		var output = '';
		checkout.billing = {};
		checkout.delivery = {};

		checkout.name = $('select[name="title"]').val() + ' ' + $('input[name="firstName"]').val() + ' ' + $('input[name="surname"]').val();
		checkout.email = $('input[name="username"]').val();
		checkout.billing.add1 = $('input[name="billingHouseNumber"]').val() + ' ' + $('input[name="billingStreet"]').val();
		checkout.billing.add2 = $('input[name="billingAddress1"]').val();
		checkout.billing.add3 = $('input[name="billingAddress2"]').val();
		checkout.billing.city = $('input[name="billingCity"]').val();
		checkout.billing.county = $('input[name="billingCounty"]').val();
		checkout.billing.state = $('select[name="billingStateCode"] option:selected').html();
		checkout.billing.postcode = $('input[name="billingPostcode"]').val();
		checkout.billing.country = $('select[name="billingCountry"] option:selected').html();

		checkout.delivery.name = $('select[name="deliveryTitle"]').val() + ' ' + $('input[name="deliveryFirstName"]').val() + ' ' + $('input[name="deliverySurname"]').val();
		checkout.delivery.add1 = $('input[name="deliveryHouseNumber"]').val() + ' ' + $('input[name="deliveryStreet"]').val();
		checkout.delivery.add2 = $('input[name="deliveryAddress1"]').val();
		checkout.delivery.add3 = $('input[name="deliveryAddress3"]').val();
		checkout.delivery.city = $('input[name="deliveryCity"]').val();
		checkout.delivery.county = $('input[name="deliveryCounty"]').val();
		checkout.delivery.state = $('select[name="deliveryStateCode"] option:selected').html();
		checkout.delivery.postcode = $('input[name="deliveryPostcode"]').val();
		checkout.delivery.country = $('select[name="deliveryCountry"] option:selected').html();

		if ( panelID === 'yourdetails' ) {
			$('.cart-details.your-details address').html( checkout.name + '<br/>' + checkout.email );
		} else if ( panelID === 'billingaddress' ) {
			output = checkout.name + '<br/>';
			output += checkout.email + '<br/>';
			output += checkout.billing.add1 +'<br/>';
			if ( checkout.billing.add2 !== '' && checkout.billing.add2 !== undefined ) { output += checkout.billing.add2 +'<br/>'; }
			if ( checkout.billing.add3 !== '' && checkout.billing.add3 !== undefined ) { output += checkout.billing.add3 +'<br/>'; }
			if ( checkout.billing.city !== '' && checkout.billing.city !== undefined ) { output += checkout.billing.city +'<br/>'; }
			if ( checkout.billing.county !== '' && checkout.billing.county !== undefined ) { output += checkout.billing.county +'<br/>'; }
			if ( checkout.billing.state !== '' && checkout.billing.state !== undefined ) { output += checkout.billing.state +'<br/>'; }
			if ( checkout.billing.postcode !== '' && checkout.billing.postcode !== undefined ) { output += checkout.billing.postcode +'<br/>'; }
			if ( checkout.billing.country !== '' && checkout.billing.country !== undefined ) { output += checkout.billing.country +'<br/>'; }

			$('.cart-details.your-details address').html( output );
		} else if ( panelID === 'deliveryaddress' ) {
			output = '';

			if ( $('input[name="sameAsBilling"]:checked') ) {
				output = checkout.name + '<br/>';
			} else {
				output = checkout.delivery.name + '<br/>';
			}

			output += checkout.delivery.add1 +'<br/>';
			if ( checkout.delivery.add2 !== '' && checkout.delivery.add2 !== undefined ) { output += checkout.delivery.add2 +'<br/>'; }
			if ( checkout.delivery.add3 !== '' && checkout.delivery.add3 !== undefined ) { output += checkout.delivery.add3 +'<br/>'; }
			if ( checkout.delivery.city !== '' && checkout.delivery.city !== undefined ) { output += checkout.delivery.city +'<br/>'; }
			if ( checkout.delivery.county !== '' && checkout.delivery.county !== undefined ) { output += checkout.delivery.county +'<br/>'; }
			if ( checkout.delivery.state !== '' && checkout.delivery.state !== undefined ) { output += checkout.delivery.state +'<br/>'; }
			if ( checkout.delivery.postcode !== '' && checkout.delivery.postcode !== undefined ) { output += checkout.delivery.postcode +'<br/>'; }
			if ( checkout.delivery.country !== '' && checkout.delivery.country !== undefined ) { output += checkout.delivery.country +'<br/>'; }

			$('.cart-details.delivery-address address').html( output );
		}
	}

	function _scrollTo(selector) {
		$('body').animate({
			scrollTop: $(selector).offset().top - $('header').outerHeight()
		}, 333);
	}

	/*
	 * Delivery options
	 */
	function getDeliveryOptions($form) {
		
		let form = $form[0];
		let select = form.querySelector('#deliveryMethod');
		let country = form.querySelector('.js-delivery-country');

		$.ajax({
			type: "POST",
			cache: false,
			url: "/js/xhr/getDeliveryOptions.php",
			data: $form.serialize(),
			success: function(response) {

				response = $.parseJSON(response);

				if (response.success) {

					if (select) {

						select.innerHTML = response.quotes;

						/** 
						 * Update the shipping tariff with the preselected option.
						 */
						Shipping.updateTariff(select.value, country.value);
					}
				} else {
					select.innerHTML = "<option>There are no shipping tariffs available for your chosen delivery country";
					Shipping.resetTariff();
				}
			},
			error: function() {
				spinner.stop();
				$form.find('button[type="submit"]').attr('disabled', false);
				select.innerHTML = "";
				alert('Sorry, there was an error processing your request.'); 
			}
		});
	}

	/*
	 * Confirm the VAT for the order - will be deducted depending on the delivery country
	 */
	function _confirmVATForOrder($form) {
		spinner.spin($form[0]);

		$.ajax({
			type: "POST",
			cache: false,
			url: "/js/xhr/confirmVATForOrder.php",
			data: $form.serialize(),
			success: function(response) {
				spinner.stop();

				response = $.parseJSON(response);

				var $summary = $('.summary');

				$summary.data('total', response.total);
				$summary.find('.vat-charge').html(response.vat);
				$summary.find('.total').html(response.cartTotal);
			},
			error: function() {
				spinner.stop();
				alert('Sorry, there was an error processing your request.'); 
			}
		});
	}
	/*
	$(document).on('click', '.chosen-postcode', function (e) {
		alert('clicked'); 
	});
	*/

	/*
	 * Confirm the VAT for the order - will be deducted depending on the delivery country
	 */
	function setTax() {

		$.post("/js/xhr/calculateTax.php", {
			country: $('#deliveryCountry').val(),
			shippingValue: $('#delivery').val()
		}, 
		function(response){
			var jsonData = JSON.parse(response); 

			//ok some territories dont require vat removal such as the uk
			
			if(jsonData.summary.rateUsed!='GBR'){
				$('.vatRemoval-total').slideDown(300);
				$('#vatRemoval').val('-'+jsonData.summary.formattedVatRemoval);

				if(formattedDutyTotal!='£0.00'){
					$('.duty-total').slideDown(300);
				}else{
					$('.duty-total').slideUp(300);
				}

				$('.cart-vat').slideUp(300);

				$('#duty').val(jsonData.summary.formattedDutyTotal);
				$('.localSalesTax-total').slideDown(300);
				$('#localSalesTax').val(jsonData.summary.formattedVatTotal);
				//$('#subtotal').val(jsonData.summary.formattedBasketTotal);
				$('#total').val(jsonData.summary.formattedBasketTotalIncShipping);
				$('.subTotal-total').removeClass('first');
				alert('2');
				
			}else{
				$('.vatRemoval-total').slideUp(300);
				$('#vatRemoval').val(0);
				$('.duty-total').slideUp(300);
				$('#duty').val(0);
				$('.localSalesTax-total').slideUp(300);
				$('#localSalesTax').val(0);
				$('#subtotal').val(jsonData.summary.formattedBasketTotal);
				$('#total').val(jsonData.summary.formattedBasketTotalIncShipping);
				$('.subTotal-total').addClass('first');
				$('.cart-vat').slideDown(300);
				
			}
		});
	}

	$(document).on('change', '#deliveryCountry', function (e) {
		
		$.post("/js/xhr/calculateTax.php", {
			country: $(this).val(),
			shippingValue: $('#delivery').val()
		}, 
		function(response){

			var jsonData = response;

			console.log(response);

			//ok some territories dont require vat removal such as the uk
			
			if(jsonData.summary.rateUsed!='GBR'){
				
				$('.vatRemoval-total').slideDown(300);
				$('#vatRemoval').val('-'+jsonData.summary.formattedVatRemoval);
				if(jsonData.summary.formattedDutyTotal!='£0.00'){
					$('.duty-total').slideDown(300);
				}else{
					$('.duty-total').slideUp(300);
				}
				$('.cart-vat').slideUp(300);
				$('.localSalesTax-total').slideDown(300);
				$('#localSalesTax').val(jsonData.summary.formattedVatTotal);
				//$('#subtotal').val(jsonData.summary.formattedBasketTotal);
				$('#total').val(jsonData.summary.formattedBasketTotalIncShipping);
				$('.subTotal-total').removeClass('first');
				$('#vat').val(jsonData.summary.formattedVatTotal);
				$('#duty').val(jsonData.summary.formattedDutyTotal);
				
			} else{
				
				$('.vatRemoval-total').slideUp(300);
				$('#vatRemoval').val(0);
				$('.duty-total').slideUp(300);
				$('.cart-vat').slideDown(300);
				$('#duty').val(0);
				$('.localSalesTax-total').slideUp(300);
				$('#localSalesTax').val(0);
				$('#subtotal').val(jsonData.summary.formattedBasketTotal);
				$('#total').val(jsonData.summary.formattedBasketTotalIncShipping);
				$('.subTotal-total').addClass('first');  
			}
		});
	});

	/**
	 * Submit order
	 */
	function submitOrder($form) {

		spinner.spin($form[0]);

		// if(!Validation.validateForm($form)) {
		// 	spinner.stop();
		// 	return false;
		// }

		$('.checkout-form-errors').html('');
 		$form.find('input').removeClass('error');
        $form.find('button[type="submit"]').attr('disabled', true);
		$form.find('fieldset').removeClass('form-error');

		$('.error-messages ul').html('');
		$('.error-messages').hide();

		// $form.find('button[type="submit"]').attr('disabled', true);

		$.ajax({
			type: "POST",
			cache: false,
			url: "/js/xhr/submitAjaxOrder.php",
			data: $form.serialize(),
			success: function(response) {
				spinner.stop();

				// response = $.parseJSON(response);

				// if(response.error) {
				// 	$form.find('button[type="submit"]').attr('disabled', false);
				// 	alert(response.error);
				// 	$('.step').eq(1).find('a').eq(0).trigger('click');
				// } else if ( response.reg_error ) {
				// 	alert(response.reg_error);
				// 	$('.step').eq(1).find('a').eq(0).trigger('click');
				// } else {
				// 	// Redirect the user to SagePay
				// 	window.location.href = response.url;
				// }

				try {
                    response = $.parseJSON(response);
                    if(response.error) {
                        $form.find('button[type="submit"]').attr('disabled', false);

						if(response.fields !== undefined) {

							var errorListItems = '',
								hasError = false,
								iError = 0;

							for(iError = 0; iError < response.fields.length; iError++) {

								var error = response.fields[iError];
								if(error.status === false) {
									hasError = true;

									var fieldObj = $form.find('#'+error.field);
									if(fieldObj.length > 0) {
										fieldObj.parent('fieldset').addClass('form-error');

										var message = fieldObj.data('error-title') ;
										if(error.message !== undefined && error.message.length > 0) { message = error.message; }


										if(message !== undefined) {
											errorListItems += '<li class="scroll-to-ele" data-target="'+error.field+'">';
											errorListItems += '<strong>Error:</strong> '+message;
											errorListItems += '</li>';
										}
									}

								}

							}


							if(hasError) {
								$('.error-messages ul').html(errorListItems);
								$('.error-messages').show();

					            $('html, body').animate({
					                scrollTop: $('.error-messages').offset().top - 300
					            }, 200);
							}

						} else {
	                        $('.checkout-form-errors').html(response.error);
	                        $('.step').eq(1).find('a').eq(0).trigger('click');
						}
                    } else {
                        // Redirect the user to SagePay
                        window.location.href = response.url;
                    }
				}
				catch (e) {
					$('.checkout-form-errors').html('An error occurred. Please contact us if you require further assistance.');
		            $form.find('button[type="submit"]').attr('disabled', false);
				}



			},
			error: function() {
				spinner.stop();
				alert('Sorry, there was an error processing your request.');
			}
		});
	}


	function updateMiniCart(data) {

		return $.post( "/js/xhr/cartAction.php", data, function(data) {
		   // Reload Mini Cart...
		   $('.shopping_bag_count').html( data.totalQty );
		   $('#miniCart').html( $(data.contents).html() );
		}, 'json');

	}



	function initializeDropKick() {

		$(".step select").dropkick({
			mobile: true
		});

	}

	function closeMiniCart() {

		$('#miniCart').fadeOut( 300 );

	}


	// return pointers to public methods and properties you want to reveal
	return {
		initAccordion: initAccordion,
		submitOrder: submitOrder,
		updateMiniCart: updateMiniCart,
		initializeDropKick: initializeDropKick,
		getAddressFieldsSelector: getAddressFieldsSelector,
		getDeliveryOptions: getDeliveryOptions,
		closeMiniCart: closeMiniCart,
		init: init
	};
}();

export default EskimoCheckout;