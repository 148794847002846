

class productSlider {


    constructor(sliderSection) {

        this.section = sliderSection;
        this.init();

    }

    init() {

        let mobileSlider = this.section.querySelector('.mobile-slider .flex');
        let desktopSlider = this.section.querySelector('.desktop-slider');
        let recentlyViewedDesktop = document.querySelector('.recently-viewed .desktop-slider');
        let recentlyViewedMobile = document.querySelector('.recently-viewed-mobile .flex');

        let productSliderMobileFB =  document.querySelector('.productSliderMobileFB');
        let mobileSliderFallback = document.querySelector('.recently-viewed__container .mobile-slider .flex');
       
        if(mobileSlider) {

            $(mobileSlider).owlCarousel({
                loop: true,
                items: 1,
                nav: false,
                pagination: false,
            });

        }

        if(recentlyViewedMobile) {

            $(recentlyViewedMobile).owlCarousel({
                loop: true,
                items: 2,
                nav: false,
                pagination: false,
                dots: true
            });

        }

        if(productSliderMobileFB) {

            $(productSliderMobileFB).owlCarousel({
                loop: true,
                items: 1,
                nav: false,
                pagination: true,
                dots: true,
                mouseDrag: false,
                touchDrag: true
            });

          

        }

        if(desktopSlider) {

            $(desktopSlider).owlCarousel({
                loop: false,
                items: 4,
                nav: false,
                pagination: false
            });

        }

        if(recentlyViewedDesktop) {

            $(recentlyViewedDesktop).owlCarousel({
                loop: true,
                items: 6,
                nav: false,
                pagination: false,
                responsive:{
                    1500: {
                        items: 6,
                        nav: false,
                        loop: true
                    },
                    1250:{
                        items:4,
                        nav:false,
                        loop: true
                    },
                    1000: {
                        items: 2,
                        nav: false, 
                        loop: true
                    },
                    800: {
                        items: 1,
                        nav: false,
                        loop: true
                    }
                }
            });

        }

        if(mobileSliderFallback) {

            $(mobileSliderFallback).owlCarousel({
                loop: true,
                items: 1,
                nav: false,
                pagination: false
            });

        }

    }


}

export default productSlider;


document.addEventListener("DOMContentLoaded", function () {

    let t = document.querySelector('.you-might-also-like');
    let a = document.querySelector('.recently-viewed');

    if(t) {

        new productSlider(t);

    }

    if(a) {

        new productSlider(a);

    }


});