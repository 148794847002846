/**
 * Fade Delay
 * Author: Maiko McElroy
 */

/* global Utils */

var Fadedelay = (function() {

	var options = {
        element: $('.js-fade-delay [class^=pod__]')
    };

    function canInit() {
        if (options.element.length) {
            return true;
        }
        return false;
    }

	function init(params) {
        options = $.extend({}, options, params);

        if (canInit()) {

            initFadedelay();
            
            console.log('[Fadedelay] initialized');
        }
    }

    function initFadedelay(){

        $(window).on('load', function() {
            options.element.fadeDelay({
                interval : 35
            });
        });
    }

    return {
    	init: init
    };

})();

export default Fadedelay;