var Menu = (function() {

    var menuAnimated = false;
    var staticMenuOpen = false;

    function init()
    {
        console.log('[Menu] init');
        adjustMenu($(window).width());
        $(window).bind('resize orientationchange', function() {
            adjustMenu($(window).width());
        });

        $('.small_menu').click(function(){
            if($('.small_menu.close').length) {
                hideStaticMenu();
            } else {
                showStaticMenu();
            }
        });

        //change header to sticky
        $(window).on("scroll",function(){
            scrollMenu()
        });
        scrollMenu();

        
    }

    function scrollMenu() {
        if($(window).width() > 767){
            if($(document).scrollTop()>40){
                $("#division-header").addClass("fixed_nav");
            } else{
                $("#division-header").removeClass("fixed_nav");
            }
        }
    }

    function adjustMenu(ww) {
        if (ww >= 768) {
            $('.static_menu ul li').removeAttr('style');
            $(".static_menu").removeAttr('style');
        }
    }

    function hideStaticMenu() {
        menuAnimated = true;
        $(".static_menu ul li").fadeDelay({
            interval : 35,
            fadeIn : false,
            callback : function(){
                $('.static_menu').fadeOut(300);
                $('html,body').css('overflow', 'auto');
                $('.static_menu').removeClass('overlay');
                $('.small_menu').removeClass('close');
                $('.site_top').removeClass('menu_open');
                menuAnimated = false;
            }
        });
        staticMenuOpen = false;
    }

    function showStaticMenu() {
        menuAnimated = true;
        $('html,body').css('overflow', 'hidden');
        $('.static_menu').addClass('overlay');
        $('.small_menu').addClass('close');
        $('.site_top').addClass('menu_open');
        $('.static_menu').fadeIn(300);
        $(".static_menu ul li").fadeDelay({
            interval : 35,
            callback: function(){ menuAnimated = false; }
        });
        staticMenuOpen = true;
    }

    return {
        init: init
    };

})();

export default Menu;