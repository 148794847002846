/**
 * Dropkick
 * Author: Maiko McElroy
 */

var Dropkick = (function() {
    'use strict';

	var options = {
        element: $('.js-dropkick'),
    };

    function canInit() {
        if (options.element.length) {
            return true;
        }
        return false;
    }

	function init(params) {
        options = $.extend({}, options, params);

        if (canInit()) {

            initDropkick();
            
            console.log('[Dropkick] initialized');
        }
    }

    function initDropkick(){
        
        options.element.dropkick({ mobile: true });
    }

    return {
    	init: init
    };

})();

export default Dropkick;