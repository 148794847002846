console.log("[AjaxStickyBasket] init")

var actionType = "",
productId = "",
count,
products,
drops = [],
priceOut = document.getElementById("priceOutput"),
productOut = document.getElementById("productList"),
addProd = [],
buttons = [],
cartToggles = [];
// cartOpen = document.getElementById("cartOpen");


// document.addEventListener("DOMContentLoaded", () =>{
// 	getCart();
    
// });

// WATCH FOR WINDOW OPENING

let element = document.querySelector('.sticky-basket');
var observer = new MutationObserver(function (event) {
    if(!element.classList.contains(".active")){
        
        getCart();  
    } 
})

observer.observe(element, {
  attributes: true, 
  attributeFilter: ['class'],
  childList: false, 
  characterData: false
})


// 
const scroll = () => {
    $('.sticky-basket').animate({scrollTop: document.body.scrollHeight},"slow");
}

document.addEventListener("DOMContentLoaded", () => {
    cartToggles = document.querySelectorAll(".js-toggle-basket");

    cartToggles.forEach(function(elem) {
        elem.addEventListener("click", (e)=>{
            e.preventDefault();
            getCart();
        });
    });
});
// cartOpen.addEventListener("click", (e) => {
//     e.preventDefault();
//     getCart(e);
// }, { passive: false });

const updateCart = () => {
    $.ajax({
        async: true,
        method: "POST",
        data:{
            action: actionType,
            product: productId,
            qty: count
        },	
        url: '/js/xhr/updateStickyBasket.php',
            success:function() {
                getCart();
            },
            error: function(err){
                console.log("Error: " + err);
        }
    });
}
const getCart = () => {
    document.getElementById("division-header").classList.remove("is-hidden")
    $.ajax({
        async: false,
        method: "GET",
        url: '/js/xhr/getStickyBasket.php',
            success:function(data) {
                //var json = $.parseJSON(data); 
                var json = JSON.parse(data);

                products = json.products;
                renderCart(json.currency, json.totalPrice);
                
            },
            error: function(err){
                console.log("Error: " + err);
        }
    }).then(updateButtons(), mobileDropdown(), addProduct())
}
const mobileDropdown = () => {
    drops = document.querySelectorAll(".productDropdown")
    drops.forEach(function(elem) {
        elem.addEventListener("change", ()=>{
            count = elem.value;
            let product = (elem.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.children[0].dataset.productid)
            let action = elem.dataset.action
            actionType = action;
            productId = product;
            updateCart();
        });
    });
}
const addProduct = () => {
    addProd = document.querySelectorAll(".product-add-btn:not(.is-initialised)")
    addProd.forEach(function(elem) {
        elem.classList.add("is-initialised")
        elem.addEventListener("click", function() {
            
            actionType = "insert";
            productId = elem.parentNode.children[0].value	
            updateCart();
        });
    });
}
const updateButtons = () => {
        buttons = document.querySelectorAll(".updatecart-button")
        buttons.forEach(function(elem) {
        elem.addEventListener("click", function() {
            let product = (elem.closest(".products-in-basket-product").children.item(0).dataset.productid)
            let action = elem.dataset.action
            actionType = action;
            productId = product;
            updateCart()
        });
    });
}
const renderCart = (currency, totalPrice) => {

    productOut.innerHTML = "";
    priceOut.innerHTML = "";
    let total = 0;
    let indicator = document.querySelectorAll(".js-toggle-basket")[0];
    indicator.classList.remove("itemsInBag")


    if(products && products.length > 0){

    indicator.classList.add("itemsInBag");
    for(const product of products){
        let options,
        max,
        minX;
        for(var i = 1; i <= product.stock; i++){
            if( i == product.qty){
                options += `<option selected value = ${i}>${i}</option>`
            }else {
                options += `<option value = ${i}>${i}</option>`
            }
        }
        if(product.qty <= product.stock){
            max = `<button class="js-plus plus updatecart-button" data-action = "add">+</button>`
        } else {
            max = `Only ${product.stock} available`
        }
        if(product.qty == 1){
            minX =`<button class="minus">-</button>`
        } else {
            minX =`<button class="js-minus minus updatecart-button" data-action = "sub">-</button>`
        }
        let dataString = `<div class="products-in-basket-product">
                    <div class = "productIdDiv" data-productId = "${product.id}"></div>	
                    <div class="flex">
                        <div class="one-third flex-column">
                            <picture class="product-in-basket-product__image">
                            ${product.imageUrl}
                            </picture>
                        </div>
                        <div class="two-thirds flex-column">
                            <h2 class="products-in-basket-product__title">${product.name}</h2>
                            <p class="products-in-basket-product__category">${product.collection}</p>
                            <p>${product.attributeVals}</p>
                            <p class="products-in-basket-product__price">${product.price}</p>
                            <div class="products-in-basket-product__qty desktop js-product-controls">
                                Qty:
                                ${minX}
                                <input type="text" min="1" name="qty" value="${product.qty}" class="qty" disabled>
                                ${max}
                                <button class="js-remove product-remove-button remove updatecart-button" data-action = "remove">Remove</button>
                            </div>
                            <div class="products-in-basket-product__qty mobile js-product-controls">
                                <div class="flex">

                                    <div class="flex-column one-half">
                                        Qty:
                                        <select class="product-qty productDropdown" data-action = "update" name="" data-action = "update" id="productDropdown">
                                            ${options}
                                        </select>
                                    </div>
                                    <div class="flex-column one-half">
                                        <button class="js-remove product-remove-button remove updatecart-button" data-action = "remove">Remove</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>`;
                productOut.insertAdjacentHTML('beforeend', dataString);	
                //let p =   product.price.replace(/\D/g,'');
                //symbol = product.price.replace(/[0-9]/g, '');
                //total = total +	p * product.qty;	
    }   
    }else{
    }   
    
    if (totalPrice == 0){
            productOut.innerHTML = "Your Shopping Cart is Empty"
        } else {
            priceOut.innerHTML = currency + totalPrice;

    }
}