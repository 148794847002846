// Create the Wallace Sewell "namespace"
var WS = function() {
    /**
     * Newsletter sign-up
     */
    function addToMailingList($form) {
        var newsletterEmail;
        console.log($form);
        $.post("/js/xhr/subscribe.php", $form.serialize(), function(response){
        if(response === 'Thanks. You\'re registered!') {
            $('#email_register').fadeOut(200,function(){
                $('#email_register .error').fadeOut(10);
                $('#email_register .thankyou').fadeIn(200,function(){
                    $('#email_register .thankyou').html(response);
                });
            });
        } else {
            $('#email_register .error').fadeIn(200,function(){
                $('#email_register .error').html(response);
            });
        }
        });
        return false;
    }


    function initializeGalleryCarousel() {

        $('.productGallery ul').addClass( 'owl-carousel' );

        $('.owl-carousel').owlCarousel({
            loop: true,
            items: 1,
            dots: true,
            nav: true,
            dotsContainer: '.carousel-custom-dots'
        });

        let ww = $(window).width();
        let wh = $(window).height();

        // Get on intial load
        if ( ww >= 768 ) {
            var offsetTop = $('.productGallery').offset().top;
            var galleryHeight = wh - offsetTop - 75;
            $('.productGallery').height( galleryHeight );
        }

        $(window).bind('resize orientationchange', function() {

            if ( ww >= 768 ) {
                var offsetTop = $('.productGallery').offset().top;
                var galleryHeight = wh - offsetTop - 75;
                $('.productGallery').height( galleryHeight );
            } else {
                $('.productGallery').removeAttr('style');
            }

        });

    }

    //recent stories logos slider
    function initializeRecentStoriesCarousel() {

        var owl = $("#latest_slider");

        owl.owlCarousel({
            margin: 30,
            responsiveClass:true,
            responsive:{
                0:{
                    margin: 1,
                    items:1,
                    navigation:true
                },
                600:{
                    items:3,
                    navigation:true
                },
                1000:{
                    items:5,
                    nav:false,
                    loop:false
                }
            },
            onInitialized: function() {

                var mHeight = 0;

                // Update the size of the image container to force all the images to be base aligned
                owl.find('.post').each( function() {
                    var h = $(this).find('.image img').height();
                    if ( h > mHeight ) { mHeight = h; }
                });

                $('#latest_slider .post .image').css( { 'height' : mHeight+'px' } );

            },
            onResized: function() {

                var mHeight = 0;

                // Update the size of the image container to force all the images to be base aligned
                owl.find('.post').each( function() {
                    var h = $(this).find('.image img').height();
                    if ( h > mHeight ) { mHeight = h; }
                });

                $('#latest_slider .post .image').css( { 'height' : mHeight+'px' } );

            }

        });
        // Custom Navigation Events
        $("#latest_blog .next").click(function(){
            owl.trigger('next.owl.carousel');
        })
        $("#latest_blog .prev").click(function(){
            owl.trigger('prev.owl.carousel', [300]);
        })
    }


    function initializeFilterDropKick() {

        $(".filter select").dropkick({
            mobile: true,
            change: function() {
                location.href = this.value;
            }
        });

    }


    function initializeCurrencyDropkick() {

        $(".select_currency select").dropkick({
            mobile: true,
            change: function() {
                $('.select_currency').submit();
            }
        });

    }

    function initializeOptionsDropkick() {

        $(".productChoiceSize select").dropkick({
            mobile: true,
            change: function() {
                var price;
                let basketQuantity = document.querySelector(".productQty");
                basketQuantity.value = 1;
                selectedSize = this.value;
                // If selectedColour is undefined then we've not selected a colour yet,
                // so assume the first size in the productChoiceColour select
                if (typeof selectedColour === 'undefined') {
                    selectedColour = $(".productChoiceColour select option").not(".hidden-option").val();
                    basketQuantity.setAttribute("max",stockLevels[selectedSize + '-']['stock']);

                }

                // If selectedColour is still undefined, this product has no colour options
                if (typeof selectedColour !== 'undefined') {
                    basketQuantity.setAttribute("max",100);
                    price = stockLevels[selectedSize + '-' + selectedColour]['price'];
                    basketQuantity.setAttribute("max",stockLevels[selectedSize + '-']['stock']);
                }
                else {
                    price = stockLevels[selectedSize + '-']['price'];
                    basketQuantity.setAttribute("max",stockLevels[selectedSize + '-']['stock']);
                }

                $('.productDetails .productPrice').html( $('.productDetails .productPrice').data('currency') + price );
            }
        });

        $(".productChoiceColour select").dropkick({
            mobile: true,
            change: function() {
                selectedColour = this.value;
                let basketQuantity = document.querySelector(".productQty");
                basketQuantity.value = 1;

                // If selectedSize is undefined then we've not selected a size yet,
                // so assume the first size in the productChoiceSize select
                if (typeof selectedSize === 'undefined') {
                    selectedSize = $(".productChoiceSize select option").not(".hidden-option").val();
                    basketQuantity.setAttribute("max",stockLevels[selectedSize + '-']['stock']);
                }

                // If selectedSize is still undefined, this product has no size options
                if (typeof selectedSize !== 'undefined') {
                    price = stockLevels[selectedSize + '-' + selectedColour]['price'];
                    basketQuantity.setAttribute("max",stockLevels[selectedSize + '-']['stock']);
                }
                else {
                    price = stockLevels['-' + selectedColour]['price'];
                    basketQuantity.setAttribute("max",stockLevels[selectedSize + '-']['stock']);
                }

                $('.productDetails .productPrice').html( $('.productDetails .productPrice').data('currency') + price );
            }
        });

    }


    // return pointers to public methods and properties you want to reveal
    return {
        addToMailingList: addToMailingList,
        initializeGalleryCarousel: initializeGalleryCarousel,
        initializeRecentStoriesCarousel: initializeRecentStoriesCarousel,
        initializeFilterDropKick: initializeFilterDropKick,
        initializeCurrencyDropkick: initializeCurrencyDropkick,
        initializeOptionsDropkick: initializeOptionsDropkick
    };
}();

export default WS;