var projectGallery = (function () {
    let out,
    trigger = document.getElementById("galleryTrigger")
    if(trigger){
    $(document).ready(function(){
        let urlStr = window.location.pathname;
        urlStr = urlStr.replace("/project/", "");
        $.ajax({ 
            url: "../../js/xhr/getGalleryImages.php",
            data: {
                currentUrl:  urlStr
            },
            success: function(data){
                data = data.replace("[, ]", "")
                out = JSON.parse(data)
            }
        });
    });
    }
    
    if(trigger){
        trigger.addEventListener("click", function(){
            openPhotoSwipe(out);
        });
        const openPhotoSwipe = (out) =>{
        var pswpElement = document.querySelectorAll('.pswp')[0];

        // build items array
        
        var items = [
        ];
        for(var i = 0; i < out.length; i++){
            items.push(out[i])
        }
        // define options (if needed)
        var options = {
            // optionName: 'option value'
            // for example:
            index: 0,// start at first slide
            closeEl:true,
            captionEl: true,
            fullscreenEl: true,
            zoomEl: true,
            shareEl: true,
            counterEl: true,
            arrowEl: true,
            preloaderEl: true,
        };

        // Initializes and opens PhotoSwipe
        var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);

        gallery.init();
        };
    }
})();

export default projectGallery;