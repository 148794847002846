import Panzoom from '@panzoom/panzoom'

class productImageZoom {

    constructor(image) {

        this.productImage = image;
        this.zoomButton = this.productImage.parentElement.parentElement.querySelector('.js-zoom-trigger');
        this.init();

    }

    init() {

        this.zoomToggle();
    }

    zoomToggle() {

        if(this.zoomButton) {

            let panzoom = Panzoom(this.productImage, {
                contain: 'outside',
                panOnlyWhenZoomed: true,
                startScale: 1,
                maxScale: 2
            });

            let clickCount = 0;


            this.zoomButton.addEventListener('click', (e) => {

                clickCount++;
        
                // console.log(panzoom);

                if (clickCount === 1) {
                    panzoom.zoom( 1.5, { 
                        animate: true 
                    });
                    this.productImage.classList.add('is-zoomed');
                } else if (clickCount === 2) {
                    panzoom.zoom( 2, { 
                        animate: true 
                    });
                    this.productImage.classList.add('zoom-out');
                } else {
                    panzoom.reset({ animate: true});
                    this.productImage.classList.remove('is-zoomed', 'zoom-out');
                    clickCount = 0;
                }


            });

        } else {

        }

    }

}

export default productImageZoom;

document.addEventListener("DOMContentLoaded", function () {

    const productImages = document.querySelectorAll('.product-image .image');
    console.log(productImages);

    if(productImages) {

        productImages.forEach(image => {

            new productImageZoom(image);

        });

    }


});