var TradeOrdersLink = (function () {

    function init() {
        console.log('[Trade Orders Link] init');
        var tableRows = document.querySelectorAll('.table-orders .table-row[data-href]')

        if (tableRows.length > 0) {
            tableOrdersLink(tableRows);
        }
    }

    function tableOrdersLink(elements) {
        elements.forEach((row, i) => {
            row.addEventListener("click", e => {
                window.location = row.dataset.href;

            });
        });
    }

    return {
        init: init
    };

})();

export default TradeOrdersLink;