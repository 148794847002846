import Utils from './utils';

var Matchheight = (function() {

	var options = {
        element: $('.js-match-height'),
        mobile: 'match-mobile',
    };

    function canInit() {
        if (options.element.length) {
            return true;
        }
        return false;
    }

	function init(params) {
        options = $.extend({}, options, params);

        if (canInit()) {

            initMatchHeight();
            
            console.log('[Matchheight] initialized');
        }
    }

    function initMatchHeight(){

        if(options.element.hasClass(options.mobile)){

            options.element.matchHeight();
            
        } else {

            if(Utils.isDesktopView() || Utils.isTabletView()){
                options.element.matchHeight();
            }
        }
        
    }

    return {
    	init: init
    };

})();

export default Matchheight;