// var masonrycomps = function() {

    function displayJournal(){
        var $journalGrid = $('.journal-menu__container').imagesLoaded( function() {
            console.log("journal loaded")
        // init Masonry after all images have loaded - fixes overlapping issues
        $journalGrid.masonry({
        // columnWidth: '.journal-sizer',
        percentPosition: true,
        gutter: 40
        });
    });
    }

    function displaySocials(){
    var $socialGrid = $('.social-menu__container').imagesLoaded( function() {
    // init Masonry after all images have loaded - fixes overlapping issues
    $socialGrid.masonry({
    itemSelector: '.social-menu__container-item',
    columnWidth: '.social-sizer',
    percentPosition: true,
    gutter: 10
    });
    });
    }

//     return {
//         displayJournal: displayJournal,
//         displaySocials: displaySocials
//     };
// }();

// export default masonrycomps;