/**
 * Checkbox
 * Author: Maiko McElroy
 */

var Checkbox = (function() {
    'use strict';

	var options = {
        element: $('.js-checkbox')
    };

    function canInit() {
        if (options.element.length) {
            return true;
        }
        return false;
    }

	function init(params) {
        options = $.extend({}, options, params);

        if (canInit()) {

            initCheck();
            
            console.log('[Checkbox] initialized');
        }
    }

    function initCheck(){

        options.element.unbind('click').click(function(event){
            event.stopPropagation();
            event.preventDefault();

            var checkbox = $(this).find('input'),
                hiding = $(this).next();

            if(checkbox[0].checked){
                checkbox.attr('checked', false);
            } else {
                checkbox.attr('checked', true);
            }
            
            checkbox.toggleClass('checked');
            checkbox.trigger('change');
            hiding.find('input,select').attr('disabled', !hiding.find('input').first().is(':disabled'));
            hiding.find('select').dropkick('refresh');
            hiding.slideToggle();
        });
    }

    return {
    	init: init
    };

})();

export default Checkbox;