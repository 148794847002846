import WS from './ws';
import EskimoCheckout from './checkout';
import Validation from './validation';

var OldCrap = (function() {

    var ww = $(window).width();
    var wh = $(window).height();

    function init()
    {
        $(window).bind('resize orientationchange', function() {
            ww = $(window).width();
            wh = $(window).height();
        });

        /*
         * Mailing list subscription
         */
        $('.site_top span.newsletter').on( 'click touchstart' , function(e) {
            e.preventDefault();
            $('#newsletter_box').fadeIn();
        });

        $('#email_register').on('submit', function(e) {
            e.preventDefault();
            WS.addToMailingList($(this));
        });

        $('#newsletter_box #close').on( 'click touchstart' , function(e) {
            e.preventDefault();
            $('#newsletter_box').fadeOut();
        });

        if ( $('.list_items').length ) {
            $('.list_items .item .image img').each(function(i) {
                $(this).parent().parent().delay(i * 100).queue(function(){
                    $(this).addClass('faded');
                });
            });
        }

        //Blog post ajax show more blog posts
        $(document).on( 'click' , '.pagination .load_more a' , function(e) {
            e.preventDefault();
            console.log('clicking');
            var url = $(this).attr('href');
            var opts = url.replace( window.location.pathname + '?' , '' );

            $('.news_posts').addClass( 'loading' );
            $('.pagination').html(''); 

            $.ajax({
                type: "GET",
                cache: false,
                url: "/js/xhr/loadBlogPosts.php",
                data: opts,
                dataType: 'JSON',
                success: function(response) {
                    $('.news_posts').removeClass('loading');
                    $('.news_posts').append( response.articles );

                    var page = (response.paging).replace( 'FILTER_PATH' , window.location.pathname );
                    $('.pagination').html( page );
                },
                error: function() {
                    $('.news_posts').html( "<div class=\"container\"><h2>No Blog Posts Found</h2></div>" ).removeClass('loading');
                }
            });

        });

        $('.tabs li').click(function(){
            var tab_id = $(this).attr('data-tab');

            $('ul.tabs li').removeClass('current');
            $('.tab-content').removeClass('current');

            $(this).addClass('current');
            $("#"+tab_id).addClass('current');
        });

        if ( $('.productGallery ul li').length > 1 ) {
            WS.initializeGalleryCarousel();
        }
        //latest stories on home page
        if ( $('#latest_slider .post').length > 1 ) {
            WS.initializeRecentStoriesCarousel();
        }

        if ( $('.filter').length ) {
            WS.initializeFilterDropKick();
        }

        if ( $('.select_currency').length ) {
            WS.initializeCurrencyDropkick();
        }

        if ( $('.productChoices').length ) {
            WS.initializeOptionsDropkick();
        }

        if ( $('#slider .slide').length > 1 ) {
            $('#slider').addClass('fadein');

            $('#slider .slider_inner').slick({
                arrows: false,
                speed: 500,
                fade: true,
                cssEase: 'linear',
                autoplay: true,
                autoplaySpeed: 4000,
                pauseOnHover:false
            });

            $('#slider .slider_inner').on('beforeChange', function(event, slick, currentSlide, nextSlide){
                $('.slider_nav li').removeClass('active');
                $('.slider_nav li').eq( nextSlide ).addClass('active');
            });

            $('.slider_nav a').on( 'click' , function(e) {
                e.preventDefault();
                var index = $(this).parent().index();
                $('#slider .slider_inner').slick( 'slickGoTo', index );
            });

        }

        $(document).on( 'click' , '.recentlyViewed .removeItem' , function(e) {
            e.preventDefault();
            $(this).parent().remove();

            $.ajax({
                type: "GET",
                cache: false,
                url: "/js/xhr/removeRecentItem.php",
                data: { id: $(this).data('id') }
            });

        });

        //Updates page
        if($('.masonry_items').length) {
            //Masonry for the social updates page
            var $container = $('.masonry_items');
            // initialize Masonry after all images have loaded
            $container.imagesLoaded( function() {
                $container.masonry({
                    columnWidth: '.grid-sizer',
                    gutter: '.gutter-sizer',
                    itemSelector: '.item'
                });
            });
        }

        // Projects page
        if($('.list_items.projects').length) {
            //Masonry for the social updates page
            var $container = $('.list_items.projects');
            $container.addClass('masonry_active');

            // initialize Masonry after all images have loaded
            $container.imagesLoaded( function() {
                $container.masonry({
                    columnWidth: '.item',
                    gutter: '.gutter-sizer',
                    itemSelector: '.item'
                });
            });
        }

        // Press page
        if($('.list_items.press').length) {
            //Masonry for the social updates page
            var $container = $('.list_items.press');
            $container.addClass('masonry_active');

            // initialize Masonry after all images have loaded
            $container.imagesLoaded( function() {
                $container.masonry({
                    columnWidth: '.item',
                    gutter: '.gutter-sizer',
                    itemSelector: '.item'
                });
            });
        }

        //search
        $('.site_top span.search').on( 'click touchstart' , function(e) {
            e.preventDefault();
            $('#search_box').fadeIn();
        });
        $('#search_box #close').on( 'click touchstart' , function(e) {
            e.preventDefault();
            $('#search_box').fadeOut();
        });

        //my account
        $(".site_form select").dropkick({
            mobile: true
        });

        //mini cart
        $(".miniCartContents").niceScroll();

        // Order submission
        $('.eskimo-checkout form').submit(function(event) {
            event.preventDefault();

            EskimoCheckout.submitOrder($(this));
        });


        if ( $('.qtySelect').length ) {
            $('.qtySelect').dropkick({ mobile: true });
        }


        /*----------------------------------------------------
        Add to basket animation
        ------------------------------------------------------*/

        var miniCartTimeout;

        // $(document).on( 'mouseenter mouseleave touchend touchstart', '.site_top .right a.shopping_bag' , function(e){
        $(document).on( 'click', '.site_top .right a.shopping_bag' , function(e){
            e.preventDefault();
            
            $('#miniCart').slideToggle();
            // if ( e.type == 'mouseenter' || e.type == 'touchstart' ) {
            //  $('#miniCart').fadeIn( 300 );
            //  clearTimeout( miniCartTimeout );
            // } else if ( e.type == 'mouseleave' || e.type == 'touchend' ) {
            //  miniCartTimeout = setTimeout( "EskimoCheckout.closeMiniCart()" , 3000 );
            // }

        });

        // $(document).on( 'mouseenter mouseleave touchend touchstart' , '#miniCart' , function(e) {
        //     e.preventDefault();

        //     if ( e.type == 'mouseenter' || e.type == 'touchstart' ) {
        //         clearTimeout( miniCartTimeout );
        //     } else if ( e.type == 'mouseleave' || e.type == 'touchend' ) {
        //         miniCartTimeout = setTimeout( "EskimoCheckout.closeMiniCart()" , 3000 );
        //     }

        // }, {passive: false});

        // $(document).on( 'mouseenter mouseleave' , '#miniCart' , function(e) {
        //         e.preventDefault();

        //         if ( e.type == 'mouseenter' || e.type == 'touchstart' ) {
        //             clearTimeout( miniCartTimeout );
        //         } else if ( e.type == 'mouseleave' || e.type == 'touchend' ) {
        //             // miniCartTimeout = setTimeout( "EskimoCheckout.closeMiniCart()" , 3000 );
        //         }

        //     });
        //  $(document).on( 'touchend touchstart', function(e) {
        //     e.preventDefault();

        //     if ( e.type == 'mouseenter' || e.type == 'touchstart' ) {
        //         clearTimeout( miniCartTimeout );
        //     } else if ( e.type == 'mouseleave' || e.type == 'touchend' ) {
        //         miniCartTimeout = setTimeout( "EskimoCheckout.closeMiniCart()" , 3000 );
        //     }

        // }, {passive: false});





        $(document).on('click touchstart','.hideCart',function(e){
            e.preventDefault();
            //console.log('clicking close');
            $('#miniCart').fadeOut( 300 );
        });

        $(document).on('touchstart','.miniCartCheckout .pink_btn',function(e){
            e.preventDefault();
            window.location.href = $(this).attr('href');
        });

        // $('body').on('touchstart', function(e){
        //  miniCartTimeout = setTimeout( "EskimoCheckout.closeMiniCart()" , 3000 );
        // });

        /*----------------------------------------------------
        Add to basket functionality
        ------------------------------------------------------*/

        // $('.addToCartButton:not(.disabled)').click(function(e) {
        //     // console.log('clicking button');
        //     e.preventDefault();
        //     $('form[name="addToCartForm"]').submit();
        // });

        // $('form[name="addToCartForm"] .productOpts').on( 'change' , function(e) {

        //     var item = $(this).val();

        //     // if ( stockLevels[item].stock <= 0 ) {
        //     //  $('form[name="addToCartForm"] input[name="qty"]').prop( 'disabled' , true );
        //     //  $('form[name="addToCartForm"] button[type="submit"]').text('Out of Stock').prop( 'disabled' , true );
        //     // } else {
        //     //  $('form[name="addToCartForm"] input[name="qty"]').prop( 'disabled' , false );
        //     //  $('form[name="addToCartForm"] button[type="submit"]').text('Add to Bag').prop( 'disabled' , false );
        //     // }
        // });

        // $('form[name="addToCartForm"]').on( 'submit' , function(e) {

        //     e.preventDefault();

        //     $('.productOpts').removeClass('error');
        //     $('.error_msg').remove();

        //     if (!$(this).find('[name=qty]').val()) {
        //         $(this).find('[name=qty]').addClass('error');
        //         return;
        //     }

        //     if ( $('select.productOpts').length > 0 ) {
        //         if ( $('select.productOpts').val() === '' ) {
        //             // alert( 'Please select a product option.' );
        //             $('.productChoices').prepend( '<p class="error_msg">Please select a product option below.</p>' );
        //             $('select.productOpts').addClass('error');
        //             $('select.productOpts').prev().addClass('error');
        //             return false;
        //         }
        //     }

        //     $
        //     // $('#miniCart').fadeIn( 300 );
        //     // // miniCartTimeout = setTimeout( "EskimoCheckout.closeMiniCart()" , 3000 );

        //     EskimoCheckout.updateMiniCart(
        //         $(this).serialize()
        //     ).done(function() {
        //         basketToggle()
        //         //showMiniCart();
        //         //setTimeout( hideMiniCart, 4000 );
        //     }).fail(function(error) {
        //         $('.addToCartButton').addClass('fail');
        //         // console.log(error.status, error.statusText);
        //     });


        // });
        // function basketToggle() {
        //     const basketOverlay = document.querySelector('.js-sticky-basket');
            
        //     basketOverlay.classList.add('active');
        //     document.body.classList.add('nav-active');
            // if (basketOverlay.classList.contains('active')) {
            //     basketOverlay.classList.remove('active');           
            // } else {
            //     basketOverlay.classList.add('active');
            // }
    
            // if (document.body.classList.contains('nav-active')) {
    
            //     document.body.classList.remove('nav-active');
    
            // } else {
    
            //     document.body.classList.add('nav-active');
    
            // }
    
    
        // }

        $(document).on("click touchstart", ".miniCartDeleteIcon", function(e) {
            e.stopPropagation();
            //console.log($(this).parent().data('skuid'), $(this).parent().data('quantity'));
            EskimoCheckout.updateMiniCart({
                cartAction: "remove",
                itemId: $(this).parent().data('skuid')
            }).fail(function(error) {
                //console.log(error.status, error.statusText);
            });

        });

        // $(document).on( 'click' , '.scroll-to-ele' , function(){

        //     var dataTarget = $(this).data('target'),
        //         targetObj = $('label[for="'+dataTarget+'"]');

        //     if(targetObj.length > 0) {
        //         $('html, body').animate({
        //             scrollTop: targetObj.offset().top - 300
        //         }, 200);
        //     }

        // });


        $(document).on( 'change', 'select[name="billingCountry"], select[name="deliveryCountry"]', function(e) {

            if ( $(this).val() === '235' ) {
                $('.us-state').show();
            } else {
                $('.us-state').hide();
            }

            let elem = e.target;
            let form = elem ? elem.form : false;

            if (form) {
                EskimoCheckout.getDeliveryOptions( $(form) );
            }
        });


        $(document).on( 'change' , '.get-delivery-options fieldset select' , function() {

            if ( $(this).val() !== '0' ) {
                var total = parseFloat( $('.eskimo-checkout .summary').data('subtotal') );
                var shipping = ($(this).val()).split('-');
                var shipping_cost = parseFloat( shipping[0] );

                total += shipping_cost;

                total = total.toFixed(2);
                shipping_cost = shipping_cost.toFixed(2);

                $('.eskimo-checkout .summary .delivery-charge span').html( shipping_cost );
                $('.eskimo-checkout .summary .total span').html( total );

            }

        } );


        $(document).on('change', 'input[name="sameAsBilling"]', function() {

            if ( $(this).prop('checked') ) {

                $('.delivery-address .ship-to-billing').show();

                if($(window).innerWidth() <= 768) {
                    $('.delivery-content').css({'max-height': '120px', 'overflow-y' : 'hidden'});
                }

            } else {


                var billingFields  = EskimoCheckout.getAddressFieldsSelector('billing');
                var deliveryFields = EskimoCheckout.getAddressFieldsSelector('delivery');
                $('.delivery-address .ship-to-billing').hide();

                $('.delivery-content').css({'max-height': 'none', 'overflow-y' : ''});

                // Update delivery fields to match the billing fields
                $.each(deliveryFields, function(key, selector) {

                    var fieldObj = $(selector),
                        billingFieldObj = $(billingFields[key]);

                    if(key === 'title' && billingFieldObj.val() !== '0') {
                        var dkObj = fieldObj.parent('fieldset').find('.dk-select');
                        $(selector).val(billingFieldObj.val());
                        dkObj.find('.dk-selected').html(billingFieldObj.val());


                        dkObj.find('.dk-select-options li').each(function(){
                            $(this).attr('aria-selected', 'false');
                            if ($(this).text() === billingFieldObj.val()){
                                $(this).attr('aria-selected', 'true');
                            }
                        });

                    }

                    if(key === 'firstName' || key === 'surname') {
                        $(selector).val($(billingFields[key]).val());
                    }
                });


            }

        });

        $(document).on('change', 'input[name="sameAsBillingAccount"]', function() {
            if ( $(this).prop('checked') ) {
                $('.deliveryDetails').hide();
            } else {
                $('.deliveryDetails').show();
            }
        });


        $(document).on( 'submit' , '#create_password' , function(e) {

            e.preventDefault();

            var $form = $(this);
            $form.addClass( 'processing' );

            if(!Validation.validateForm($form)) {
                $form.removeClass( 'processing' );
                return false;
            }

            $.ajax({
                type: "POST",
                cache: false,
                url: "/js/xhr/createAccount.php",
                data: {
                    password: $form.find('input[name="password"]').val()
                },
                success: function(response) {
                    $form.removeClass( 'processing' );
                    $form.html( '<p>' + response + '</p>' );
                },
                error: function() {
                    $form.removeClass( 'processing' );
                    alert('Sorry, there was a problem setting up your account, please try again.');
                }
            });


        });

        /*----------------------------------------------------
        Basket / checkout
        ------------------------------------------------------*/
        $('.updateQty select').on( 'change' , function() {
            $(this).parent().submit();
        });

        if ( $('.step').length ) {
            EskimoCheckout.initializeDropKick();
        }
    }

    return {
        init: init
    }

})();

export default OldCrap;