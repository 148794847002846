import axios from "axios";

class subscribe {

    constructor(form) {

        this.form = form;
        this.init();

    }

    init() {

        this.form.addEventListener("submit", (e) => {

            e.preventDefault();

            this.subscribeRequest();


        });


    }

    subscribeRequest() {

        let formData = new FormData();
        let output = document.getElementById("subscribeMessage");
        const email = this.form.querySelector('.formInput').value;
        let formInputs = document.querySelectorAll(".subFormIn");
        formData.append('email', email);

        let request = {
            method: "POST",
            url: `/js/xhr/subscribe.php`,
            data: formData,
        };

        this.makeRequest(request, (response) => {
            if(response.data === "You're signed up"){
                output.innerHTML = "You're signed up <img class = 'subSuccess' src = '/images/site/tick.svg'>";
                formInputs.forEach((input) => {
                    input.style.display = "none"
                });
            } else {
             output.innerHTML = response.data
            }
        });

    }

    makeRequest(request, callback) {

        axios(request).then((response) => {
            if (response.status == 200 || response.status == 201) {
                callback(response);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

}

export default subscribe;

document.addEventListener("DOMContentLoaded", function () {

    const subscribeForm = document.querySelector('.js-subscribe-form');

    if(subscribeForm) {

        new subscribe(subscribeForm);

    }

});