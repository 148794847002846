let star = document.querySelectorAll('.star'),
loadMore = document.querySelectorAll('.shop-new__load-button')[0];
if(star.length > 0 ){
    console.log("[FAVOURITES] Init")

    let starsHTML = document.querySelectorAll(".star"),
    stars = [...starsHTML];
    document.addEventListener("DOMContentLoaded", () => {
        addClick(stars)
    })
    // ON CLICK
    const addClick = (stars) => {
        stars.forEach((star) => {
            star.addEventListener("click", (e)=> {

            let product = star.dataset.product;
            e.preventDefault();
            $.ajax({
                async: false,
                method: "POST",
                data:{
                    product: product,
                },	
                url: '/js/xhr/userFavouriteActions.php',
                    success:function(data) {
                        // console.log(data)
                        star.innerHTML = data
                    },
                    error: function(err){
                        console.log("Error: " + err);
                }
            })
        });
    });
    }
}

