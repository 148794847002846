import form from './form';

var Copy = (function() {
    'use strict';

	var options = {
        element: $('.js-copy'),
        remove: $('[class^="remove-"]'),
    };

    function canInit() {
        if (options.element.length) {
            return true;
        }
        return false;
    }

	function init(params) {
        options = $.extend({}, options, params);

        if (canInit()) {

            initForm();
            initRemove();
            
            console.log('[Copy] initialized');
        }
    }

    function initForm(){
        
        options.element.unbind('click').click(function(){

            var target = $('#'+$(this).data('target')),
                copyAppendTo = $('#'+$(this).data('copyappendto')),
                type = $(this).data('type');

            var nb = copyAppendTo.data('nb') + 1;

            var cloned = target.clone();
            cloned.removeAttr('id');
            console.log(cloned);

            // clear all input values
            var inputs = cloned.find('input'); 
            inputs.each(function(i,elem){
                if($(elem).prop('type') == 'hidden'){
                    $(elem).remove();
                }
                var label = $(elem).closest('fieldset').find('label[for='+$(elem).prop('id')+']');
                if(label.length){
                    label.prop('for', label.prop('for').replace(0, nb));
                }
                $(elem).prop('id', $(elem).prop('id').replace(0, nb));
                $(elem).prop('name', $(elem).prop('name').replace('[0]', '['+nb+']'));
                $(elem)[0].defaultValue = ''; 
            });  

            // clear select value
            var selects = cloned.find('select');
            selects.each(function(i,elem){
                var label = $(elem).closest('fieldset').find('label[for='+$(elem).prop('id')+']');
                if(label.length){
                    label.prop('for', label.prop('for').replace(0, nb));
                }
                $(elem).prop('name', $(elem).prop('name').replace('[0]', '['+nb+']'));
                $(elem).prop('id', $(elem).prop('id').replace(0, nb));
                $(elem)[0][0].defaultSelected = true;
                for(i=1;i<=4;i++){
                    $(elem)[0][i].defaultSelected = false;
                }
            });

            // remove some stuff
            cloned[0].firstElementChild.innerText = type +' ' + nb;
            cloned.find('.dk-select').remove();
            cloned.find('p').remove();
            cloned.find('.js-dropkick').removeAttr('data-dkcacheid');
            cloned.attr('class', 'fade-in-me-' + nb);
            cloned.find('.fieldset:last-of-type').after('<span class="js-remove-copy" data-copyappendto="'+$(this).data('copyappendto')+'">REMOVE</span>');

            var html = cloned[0].outerHTML;
                html = html.replace(/-1/g, '-' + nb);

            // fadein html dynamically
            copyAppendTo.append(html);
            $('.fade-in-me-' + nb).hide().fadeIn();

            // initialize dropkick
            $('.js-dropkick').dropkick({ mobile:true });

            copyAppendTo.data('nb', nb);
            form.initCountryChange();
        });
    }

    function initRemove(){

        $('form').on('click', '.js-remove-copy', function() {
            var field = $(this).parent();

            if(field[0].localName == 'fieldset') {
                field.remove();
            }
            let appendTo = $('#'+$(this).data('copyappendto'));
            appendTo.data('nb', appendTo.data('nb') - 1);
        });
    }

    return {
    	init: init
    };

})();

export default Copy;