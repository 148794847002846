/**
 * Order
 * Author: Maiko McElroy
 */
import utils from './utils';

var tradeAccount = (function() {
    'use strict';

    var options = {
        infoForm: $('.trade .form-my-account'),
        applyForm: $('.form-trade.form-application'),
        terms : $('.form-trade.form-application label.checkbox.terms')
    };

    function init(params) {
        if(options.infoForm.length){
            bindInfoForm();
        }
        if(options.applyForm.length){
            bindApplyForm();
        }
    }

    function bindInfoForm(){
        options.infoForm.on('validated', function(e, data){
            data.e.preventDefault();
            utils.ajax("/js/xhr/trade/submitAccount.php", $(this).serializeArray(), 'POST')
            .done(function(){  
                location.reload();
            });
        });
    }

    function bindApplyForm()
    {
        var checkbox = options.applyForm.find('#use-billing');
        changeTermsLink(options.applyForm.find('#bill-country').val());
        changeTermsLink(options.applyForm.find('#del-country').val());
        options.applyForm.find('#bill-country').change(function() {
            if (checkbox.attr('checked') != 'checked') {
                return;
            }
            changeTermsLink($(this).val());
        });
        options.applyForm.find('#del-country').change(function() {
            changeTermsLink($(this).val());
        });
        checkbox.change(function(){
            if (checkbox.attr('checked') == 'checked') {
                changeTermsLink(options.applyForm.find('#bill-country').val());
            } else {
                changeTermsLink(options.applyForm.find('#del-country').val());
            }
        });
        options.terms.find('a').click(function(e){
            e.preventDefault();
            var country = $(this).data('country');
            if (typeof country != 'undefined') {
                window.open($(this).attr('href')+'?country='+country);
            }
        });
    }

    function changeTermsLink(country)
    {
        options.terms.find('input').attr('checked', false);
        if (typeof country == 'undefined') {
            options.terms.find('a').removeData();
            options.terms.slideUp();
            return;
        }
        options.terms.find('a').data('country', country);
        options.terms.slideDown();
    }

    return {
        init: init
    };

})();

export default tradeAccount;