import axios from "axios";

var Shipping = (function () {
       
    const selector = document.querySelector('#deliveryMethod');

    function init() {

        if (!selector) {
            return;
        }

        addEvents(selector);
    }
    
    /**
     * Listen to change event on the shipping tariff selector.
     * @param {Element} elem 
     */
    function addEvents(elem) {

        elem.addEventListener('change', (e) => {
            updateTariff(elem.value);
        });
        
        elem.addEventListener('deliveryCountryChanged', (e) => {
            let deliveryCountry = document.querySelector('[name=deliveryCountry]');
            updateTariff(elem.value, deliveryCountry.value);
        });
    }

    /**
     * Sets the new shipping tariff.
     * @param {int} tariff 
     * @param {int} country 
     */
    function updateTariff(tariff, country) {

        console.log("Updating tariff");

        let formData = new FormData();

        formData.append('shippingTariff', tariff);

        if (typeof country !== 'undefined') {
            formData.append('country', country);
        }else{
            formData.append('country', $('#deliveryCountry').val());
        }

        let request = {
            method: "POST",
            url: `/js/xhr/set-shipping.php`,
            data: formData,
        };

        axios(request).then((response) => {
            updateTaxInformation(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }
    
    /**
     * Reset the shipping tariff.
     */
    function resetTariff() {       
        
        let request = {
            method: "GET",
            url: `/js/xhr/set-shipping.php`
        };

        axios(request).then((response) => {
            updateTaxInformation(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }

    /**
     * Update the tax information html.
     * @param {Object} summary 
     */
    function updateTaxInformation(summary) {
        $('#total').val(summary.summary.formattedBasketTotalIncShipping);  
        $('#delivery').val(summary.summary.formattedShippingValue);
    }

    return {
        resetTariff: resetTariff,
        updateTariff: updateTariff,
        init: init
    };

})();

export default Shipping;