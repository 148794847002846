/**
 * Utils
 * Author: Maiko McElroy
 */

var Utils = function(){
    'use strict';

    var ww = $(window).width();
	var bpMd = 950;
    var bpSmMd = 768;

    var mobileView = false;
    var tabletView = false;
    var deskTopView = false;

    (function(){
        deskTopView = (ww > bpMd ? true : false );
		tabletView = (ww <= bpMd && ww >= bpSmMd ? true: false);
        mobileView = (ww < bpSmMd ? true : false);

		$(window).resize(function () {
			onResize();
		});
    })();

    function onResize() {
		ww = $(window).width();
		deskTopView = (ww > bpMd ? true : false );
		tabletView = (ww <= bpMd && ww >= bpSmMd ? true: false);
		mobileView = (ww < bpSmMd ? true : false);
	}
    
    function isDesktopView(){
        return deskTopView;
    }

    function isTabletView(){
        return tabletView;
    }
    function isMobileView() {
        return mobileView;
    }

    function ajax(url, data, method){
        return $.ajax({
            type: method,
            cache: false,
            url: url,
            data: data,
            dataType: 'JSON',
        });
    }


    /**
	 * Expose private methods or properties
	 */
	return {
        isDesktopView : isDesktopView,
        isTabletView : isTabletView,
        isMobileView : isMobileView,
        ajax: ajax
	};

}();

export default Utils;