class stickyBasket {

    constructor(basket, btn) {

        this.basketOverlay = basket;
        this.basketBtns = btn;
        this.init();

    }

    init() {

        window.addEventListener('click', (e) => {
     

            if( e.target.parentNode.parentNode.classList.contains('js-toggle-basket') || e.target.parentNode.classList.contains('js-toggle-basket') ||  e.target.classList.contains('js-toggle-basket')) {
                this.basketToggle();
            }
         });

        this.initStickyBasketWidth();

        const self = this;

        window.addEventListener('resize', function(event) {

            self.initStickyBasketWidth();

        });

    }

    basketToggle() {

        if (this.basketOverlay.classList.contains('active')) {
            document.getElementById("cartOpen").classList.remove("active");
            this.basketOverlay.classList.remove('active');
            document.body.classList.remove('nav-active');
            document.getElementById("stickyOverlay").classList.remove("active")
        } else {
            document.getElementById("cartOpen").classList.add("active");
            this.basketOverlay.classList.add('active');
            document.body.classList.add('nav-active');
            document.getElementById("stickyOverlay").classList.add("active")


        }

        // if (document.body.classList.contains('nav-active')) {

        //     document.body.classList.remove('nav-active');

        // } else {

        //     document.body.classList.add('nav-active');

        // }


    }

    initStickyBasketWidth() {

       const body = document.getElementById('division-main');

       let bodyWidth = body.clientWidth;
       this.basketOverlay.style.right = '-'+ bodyWidth +'px';

    }

}

export default stickyBasket;

document.addEventListener("DOMContentLoaded", function () {

    const basketOverlay = document.querySelector('.js-sticky-basket');
    const basketBtn = document.querySelectorAll('.js-toggle-basket');

    if (basketOverlay && basketBtn) {

        new stickyBasket(basketOverlay, basketBtn);

    }


});
