// This is an experiment to see if i can add all products to the cart using the addToCart ajax just by slightly modifiying the forms on the page. 
console.log("[addToCart] init")
let triggers,
idTriggers,
basket = document.querySelectorAll(".js-sticky-basket")[0],
more = document.querySelectorAll(".shop-new__load-button")[0],
productId;
document.addEventListener("DOMContentLoaded", () =>{
    add()
})
if(more){
    more.addEventListener("click", () => {
        loadMore()
    })
}
const add = () => {
    idTriggers = document.querySelectorAll(".newAddToCartById");
    let idurl = '/js/xhr/addToCartById.php'
    triggers = document.querySelectorAll(".newAddToCart");
    let url = '/js/xhr/addToCart.php' 
   
    triggers.forEach(function (item, idx) {
        item.addEventListener("click", (e) => {
            e.preventDefault();
            let x = item.querySelector("#productId")
            productId = x.value;
            $.ajax({
                       async: false,
                       method: "POST",
                       data:{
                           product: productId,
                       },	
                       url: url,
                           success:function(data) {
                            //    console.log(data)
                           },
                           error: function(err){
                               console.log("Error: " + err);
                       }
                   }).then(basketToggle())
            })
        });

        idTriggers.forEach(function (item, idx) {
            item.addEventListener("click", (e) => {
                e.preventDefault();
                let x = item.querySelector("#productId")
                productId = x.value;
                $.ajax({
                           async: false,
                           method: "POST",
                           data:{
                               product: productId,
                           },	
                           url: idurl,
                               success:function(data) {
                                //    console.log(data)
                               },
                               error: function(err){
                                   console.log("Error: " + err);
                           }
                       }).then(basketToggle())
                })
            });
}

	function loadMore() {
		let parent = document.getElementById("newProductGrid"),
        starsHTML = document.querySelectorAll(".star"),
        stars = [...starsHTML];
      $.ajax({
           method: "POST",
           url: 'js/xhr/loadLatestProducts.php',
		   data: {
			   num: parent.children.length,
			   load: 8,
			},
        	success:function(data) {
				data = JSON.parse(data)
				data = data.join(" ")
				parent.insertAdjacentHTML('beforeend', data)
                add()
                let newStarsHTML = document.querySelectorAll(".star");
          
                let newStars = [...newStarsHTML],
                unCreatedStars = newStars.filter(n => !stars.includes(n))
                // Create new array from the html query minus the existing
                unCreatedStars.forEach((star) => {
                    star.addEventListener("click", (e)=> {
        
                    let product = star.dataset.product;
                    e.preventDefault();
                    $.ajax({
                        async: false,
                        method: "POST",
                        data:{
                            product: product,
                        },	
                        url: '/js/xhr/userFavouriteActions.php',
                            success:function(data) {
                                star.innerHTML = data
                            },
                            error: function(err){
                                console.log("Error: " + err);
                        }
                        })
                    });
                });
                stars = [...newStarsHTML];
			},
		   	error: function(){
			   console.log("Error")
		   }

      })
 }

 const basketToggle = () => {
    basket.classList.add('active');
    document.body.classList.add('nav-active');
}

const addFavButton = (e) => {
    e.preventDefault();
    $.ajax({
        async: false,
        method: "POST",
        data:{
            product: product,
        },	
        url: '/js/xhr/userFavouriteActions.php',
            success:function(data) {
                // console.log(data)
                star.innerHTML = data
            },
            error: function(err){
                console.log("Error: " + err);
        }
    })
}