
import axios from "axios";

class blog {

    constructor(form) {

        this.form = form;
        this.postContainer = document.querySelector('.js-blog-posts')
        this.init();

    }

    init() {

        this.initLoadMoreButton();

    }

    initLoadMoreButton() {

        this.form.addEventListener('submit', (e) => {

            e.preventDefault();

            this.loadBlogsRequest();

        });


    }

    loadBlogsRequest() {


        const index = this.form.querySelector('.blogIndex').value;

        let request = {
            method: "GET",
            url: `/js/xhr/loadBlogPosts.php?paging=` + index + ``,
        };

        this.makeRequest(request, (response) => {

            this.appendResults(response.data.articles);

            console.log(response);

            if (response.data.index && response.data.moreToLoad == true) {
                this.updateIndexInLoadMoreForm(response.data.index);
            } else {
                this.hideForm();
            }

        });



    }

    makeRequest(request, callback) {

        axios(request).then((response) => {
            if (response.status == 200 || response.status == 201) {
                callback(response);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    appendResults(results) {

        this.postContainer.innerHTML += results;

        let allPosts = this.postContainer.querySelectorAll('.blog-landing-post__container');

        if (allPosts) {

            setTimeout(function () {

                allPosts.forEach(post => {

                    post.classList.remove('hidden');

                });

            }, 1000);

        }


    }

    updateIndexInLoadMoreForm(index) {

        let blogIndex = this.form.querySelector('.blogIndex');

        if (blogIndex) {

            blogIndex.value = index;

        }

    }

    hideForm() {

        this.form.classList.add('hidden');

    }



}

export default blog;


document.addEventListener("DOMContentLoaded", function () {

    const loadMoreForm = document.querySelector('.js-load-blogs');

    if (loadMoreForm) {

        new blog(loadMoreForm);

    }


});