console.log("SEARCH")
const searchOutput = document.getElementById("searchResult");
if(searchOutput){
const mobileMenu = document.querySelectorAll(".search-filter__mobile")[0],
searchbar = document.getElementById("searchInput"),
types = [...document.querySelectorAll(".search-filter__filter--parent")[0].children],
mobileClose = document.querySelector(".search-filter__mobile-close"),
filterButton = document.getElementById("filterButton"),
clearbutton = document.getElementById("clearBTN"),
mobileScreen = window.matchMedia("(max-width:600px)");
var ajaxType = "products",
ajaxData,
checked = false,
products = [],
sizes = [],
prices = [],
collections = [],
seasons = [],
colours = [],
selectedSizes = [],
selectedPrices = [],
selectedCollections = [],
selectedSeasons = [],
selectedColours = [],
priceRange1 = false,
priceRange2 = false,
priceRange3 = false,
priceRange4 = false,
priceRange5 = false;
document.addEventListener('DOMContentLoaded', () => {
    let url = new URL(window.location.href);
    let searchParam = url.searchParams.get("search");
    if(searchParam){
        searchbar.value = searchParam
        searchOutput.innerHTML = `Search results for '${searchParam}'`
        clearbutton.style.display ="block"
    }
    mobileToggle();
    search();
    toggleCategory();
    makeCall(0, ajaxType, searchbar.value, sizes, prices, collections, colours);
    
});
clearbutton.onclick = function(){ clear() }
const clear = () => {
    clearbutton.style.display ="none"
    searchbar.value = ""
    searchOutput.innerHTML = "Search the store"
    window.history.replaceState(null, null, `?search=`);
    let outputGrid = document.querySelectorAll(".search-output__grid")[0];
    outputGrid.innerHTML = "";
    let count = document.querySelectorAll(".search-filter__filter--children-sort-count")[0];
    count.innerHTML = "";
}
const search = () => {
    searchbar.addEventListener("change", () =>{
        if(searchbar.value == ""){
            searchOutput.innerHTML = "Search the store"
            clearbutton.style.display ="none"

        } else {
            searchOutput.innerHTML = `Search results for ' ${searchbar.value} '`
            clearbutton.style.display ="block"
        }
            priceRange1 = false;
            priceRange2 = false;
            priceRange3 = false;
            priceRange4 = false;
            priceRange5 = false;
            window.history.replaceState(null, null, `?search=${searchbar.value}`);
            checked = false
            makeCall(0, ajaxType, searchbar.value, sizes, prices, collections, colours); 
        });
}


const makeCall = (filtered, type, search, sizes, prices, collections, colours) => {

    $.ajax({
        async: false,
        method: "POST",
        data:{
            filtered,
            type,
            params: search,
            sizes,
            colours,
            prices,
            collections
        },	
       
        url: 'js/xhr/search.php',
            success:function(data) {
                // console.log((data))
                ajaxData = JSON.parse(data)    
            },
            error: function(){
            console.log("Error")
        },
    }).done(
        afterAjax(type)
        )
        
}

const afterAjax = (type) => {
    
    if(type == "products"){
        if(ajaxData.length > 0){
            products = [];
            if(ajaxData[0].products){products = ajaxData[0].products}
            if(checked == false){
                colours = [];
                sizes = [];
                prices = [];
                collections = [];
                
                if(ajaxData[0].colours){colours = ajaxData[0].colours;}
                if(ajaxData[0].sizes){sizes = ajaxData[0].sizes;}
                if(ajaxData[0].prices){prices = ajaxData[0].prices;}
                if(ajaxData[0].collections){ collections = ajaxData[0].collections;}
                }
            }
            
        populateProducts(ajaxData, type)
    } else {
        populateOutput(ajaxData,type)
    };
    handleDropdowns(type);
}

const populateOutput = (data, type) => {
    let outputGrid = document.querySelectorAll(".search-output__grid")[0];
    outputGrid.innerHTML = "";
    let count = document.querySelectorAll(".search-filter__filter--children-sort-count")[0];
    count.innerHTML = data.length + " " + type;
    if(data.length < 1){
        outputGrid.innerHTML = `No ${type} results`;
    }
    if(type == "blogs"){
        for(const output of data){
            let outputString = `
            <a target = "_blank" href="/blog-post/${output.fields.friendlyUrlName}">
            <img src = "${output.fields.image}">
            <div class = "search-output__grid--item">
                <h2 class = "search-output__grid--item-title"> ${output.fields.title}</h2>
               
            </div>
        </a>`
        outputGrid.insertAdjacentHTML('beforeend', outputString)
        }
    }

    if(type == "projects"){
        for(const output of data){
            let outputString = `
            <a target = "_blank" href="/project/${output.fields.friendlyUrlName}">
            <img src = "${output.fields.image}">
            <div class = "search-output__grid--item">
                <h2 class = "search-output__grid--item-title"> ${output.fields.name}</h2>
               
            </div>
        </a>`
        outputGrid.insertAdjacentHTML('beforeend', outputString)
        }
    }
}


const populateProducts = (incomingData, type) => {
    let outputGrid = document.querySelectorAll(".search-output__grid")[0];
    outputGrid.innerHTML = "";
    let data;
    if(incomingData.length > 0 && incomingData[0].products){
        data = incomingData[0].products;
    } else {
        data = incomingData;
    }
    let count = document.querySelectorAll(".search-filter__filter--children-sort-count")[0];
    count.innerHTML = data.length + " " + type;
    if(data.length < 1){
        outputGrid.innerHTML = `No ${type} results`;
    }
    for(const output of data){
        let outputString = `
        <a target = "_blank" href="/product/${output.fields.link}">
        ${output.fields.image}
        <div class = "search-output__grid--item">
            <h2 class = "search-output__grid--item-title"> ${output.fields.name}</h2>
            <p class = "search-output__grid--item-collection"> ${output.fields.collection}</p>
            <p class = "search-output__grid--item-price"> ${output.fields.price}</p>
        </div>
    </a>`
    outputGrid.insertAdjacentHTML('beforeend', outputString)
    }
}

const toggleCategory = (type) => {
    let dropdowns = document.querySelectorAll(".search-filter__filter--children-options")
    for(let x = 0; x < dropdowns.length; x++){
        if(dropdowns[x].dataset.type == type){
            dropdowns[x].classList.add("active")
        } else {
            dropdowns[x].classList.remove("active")
        }
    }   
}

types.forEach((type) =>{
    type.addEventListener("click", (e)=> {
        for(let i = 0; i < types.length; i++){
            if(types[i] != type){
                types[i].classList.remove("active")
            } else {
                types[i].classList.add("active")
                
            }
        }
        toggleCategory(type.dataset.parent);
        ajaxType = type.dataset.parent
        makeCall(0,ajaxType, searchbar.value, false, false, false, false)
    });
});

const handleDropdowns = (type) => {
    
    let dropdowns = document.querySelectorAll(".search-filter__filter--children-options")
    for(let x = 0; x < dropdowns.length; x++){
        if(dropdowns[x].dataset.type == type){
            dropdowns[x].classList.add("active")
            
        } else {
            dropdowns[x].classList.remove("active")
        }
    } 
    
    let drops = document.querySelectorAll(".search-filter__filter--children")[0].children;
 
    let options;
            for(let j = 0; j < drops.length; j++){
                if( drops[j].dataset.type == type){
                
                    options = [...drops[j].children]
                }
            }
     
    options.forEach((option) => {
        // option.style.display = "flex"
         
        option.onclick = function(e){

            if(option.children[0] == e.target || option.children[1] == e.target || option == e.target){   
                if(this.classList.contains('filterActive')){
                    this.classList.remove("filterActive");

                } else {
                    this.classList.add('filterActive')
                    
                    for(var i = 0; i < options.length; i++){
                        if(options[i] != this){
                            options[i].classList.remove('filterActive');
                        }
                    }
                    
                }
                if(this.children[2].classList.contains("dropdownActive")){
                    this.children[2].classList.remove("dropdownActive")
                    for(var i = 0; i < [...this.children[2].children].length; i++){
                    [...this.children[2].children][i].classList.remove("active")
                    }
                } else {
                    this.children[2].classList.add("dropdownActive")
                    for(var i = 0; i < options.length; i++){
                        if(options[i].children[2] != this.children[2]){
                            options[i].children[2].classList.remove('dropdownActive');
                        }
                    }
                    for(var i = 0; i < [...this.children[2].children].length; i++){
                        [...this.children[2].children][i].classList.add("active")
                    }
                }
            }
        }
    });
     // HANDLE FILTERS
     createFilters()
};

const createFilters = () => {
    // ASSIGN EACH ATTRIBUTE TO THEIR RESPECTIVE FILTER
    let colorFilters = [...document.querySelectorAll(".filterColor")][0]
    let sizeFilters = [...document.querySelectorAll(".filterSize")][0]
    let priceFilters = [...document.querySelectorAll(".filterPrice")][0]
    let collectionFilters = [...document.querySelectorAll(".filterCollection")][0]
    let seasonFilters = [...document.querySelectorAll(".filterSeason")][0]
    if(checked == false){
        colorFilters.children[2].innerHTML = "";
        sizeFilters.children[2].innerHTML = "";
        priceFilters.children[2].innerHTML = "";
        collectionFilters.children[2].innerHTML = "";
        seasonFilters.children[2].innerHTML = "";
    }
    if(checked == false){
        if(colours.length > 0){
            colorFilters.style.display = "flex"
            for(const colour of colours){
                createCheckboxes(colour, colorFilters.children[2], "colour");
            }
        }
        else {
            colorFilters.style.display = "none"
        }
        if(sizes.length > 0){
            sizeFilters.style.display = "flex"
            for(const size of sizes){
                createCheckboxes(size, sizeFilters.children[2], "size");
            }
        }else {
            sizeFilters.style.display = "none"
        }
        if(prices.length > 0){
            priceFilters.style.display = "flex"    
            // SORT PRICE
            var sortedPrice = prices.sort((a,b) => Number(a.replace(/[^0-9.-]+/g,"")) - Number(b.replace(/[^0-9.-]+/g,"")));
            for(const price of sortedPrice){
                if (!mobileScreen.matches) {
                // createCheckboxes(price, priceFilters.children[2], "price");
                    createPriceCheckboxes(price, priceFilters.children[2], "price");
                }
            }
        } else {
            priceFilters.style.display = "none"
        }
        if(collections.length > 0){
            collectionFilters.style.display = "flex"
            for(const collection of collections){
                createCheckboxes(collection, collectionFilters.children[2], "collection");
            }
        } else {
            collectionFilters.style.display = "none"
        }
        if(seasons.length > 0){
            seasonFilters.style.display = "flex"
            for(const season of seasons){
                createCheckboxes(season, seasonFilters.children[2], "season");
            }
        }else {
            seasonFilters.style.display = "none"
        }
    }
    handleFilterSelection()
} 
const handleMobileUi = () => {
    let mobileInner = [...document.querySelectorAll(".search-filter__mobile--children-options")[0].children];
    let colorFiltersMobile = [...document.querySelectorAll(".filterColorMobile")][0]
    let sizeFiltersMobile = [...document.querySelectorAll(".filterSizeMobile")][0]
    let priceFiltersMobile = [...document.querySelectorAll(".filterPriceMobile")][0]
    let collectionFiltersMobile = [...document.querySelectorAll(".filterCollectionMobile")][0]
    let seasonFiltersMobile = [...document.querySelectorAll(".filterSeasonMobile")][0]
    if(checked == false){
        colorFiltersMobile.children[1].innerHTML = "";
        sizeFiltersMobile.children[1].innerHTML = "";
        priceFiltersMobile.children[1].innerHTML = "";
        collectionFiltersMobile.children[1].innerHTML = "";
        seasonFiltersMobile.children[1].innerHTML = "";
    }
    if(checked == false){
        if(colours.length > 0){
            colorFiltersMobile.style.display = "block"
            for(const colour of colours){
                createCheckboxes(colour, colorFiltersMobile.children[1], "colour");
            }
        }
        else {
            colorFiltersMobile.style.display = "none"
        }
        if(sizes.length > 0){
            sizeFiltersMobile.style.display = "block"
            for(const size of sizes){
                createCheckboxes(size, sizeFiltersMobile.children[1], "size");
            }
        }else {
            sizeFiltersMobile.style.display = "none"
        }
        if(prices.length > 0){
            priceFiltersMobile.style.display = "block"    
           
            // SORT PRICE
            var sortedPrice = prices.sort((a,b) => Number(a.replace(/[^0-9.-]+/g,"")) - Number(b.replace(/[^0-9.-]+/g,"")));
            for(const price of sortedPrice){
                // MEDIA QUERY
                if (mobileScreen.matches) {
                    createPriceCheckboxes(price, priceFiltersMobile.children[1], "price");
                }
            }
        } else {
            priceFiltersMobile.style.display = "none"
        }
        if(collections.length > 0){
            collectionFiltersMobile.style.display = "block"
            for(const collection of collections){
                createCheckboxes(collection, collectionFiltersMobile.children[1], "collection");
            }
        } else {
            collectionFiltersMobile.style.display = "none"
        }
        if(seasons.length > 0){
            seasonFiltersMobile.style.display = "block"
            for(const season of seasons){
                createCheckboxes(season, seasonFiltersMobile.children[1], "season");
            }
        }else {
            seasonFiltersMobile.style.display = "none"
        }
    }
    handleFilterSelection();
}


const createCheckboxes = (value, parent, type) => {

    let filtersString = `<div class = "filterOptionBox" data-type = ${type}> <label class="checkbox terms"><input type = "checkbox" name = "${type}" class = "filterCheckbox"> <span class="icon"></span><span class="label">${value}</span></label></div>`
    parent.insertAdjacentHTML("beforeend", filtersString)

}

const handleFilterSelection = () => {
    // Get all checkboxes
    let checkboxes = [...document.querySelectorAll(".filterCheckbox")];
    checkboxes.forEach((checkbox) => {
        checkbox.onclick = function(){
            let arrayName =  "selected"+ this.name.trim().replace(/^\w/, (c) => c.toUpperCase()) + "s";
            let elementName = this.parentElement.innerText.trim();
            switch(arrayName){
                case "selectedSizes":
                    if(this.checked){
                        selectedSizes.push(elementName)
                    } else {
                        var index = selectedSizes.indexOf(elementName);
                        if (index !== -1) {
                            selectedSizes.splice(index, 1);
                        }
                    }
                break;
                case "selectedPrices":
                    let alteredText = elementName.replace("-", ",");
                        alteredText = alteredText.replaceAll(" ", "")
                        alteredText = alteredText.replaceAll("£", "")
                        alteredText = alteredText.replaceAll("$", "")
                        alteredText = alteredText.replaceAll("€", "")
                        alteredText = alteredText.split(',');
                    if(this.checked){

                        selectedPrices.push(alteredText)
                        // console.log(selectedPrices);
                    } else {
                       
                        var index = indexOfArr(selectedPrices, alteredText, arraysIdentical);
                        // console.log(index)
                        if (index !== -1) {
                            selectedPrices.splice(index, 1);
                        }
                    }
                break;
                case "selectedCollections":
                    if(this.checked){
                        selectedCollections.push(elementName)
                    } else {
                        var index = selectedCollections.indexOf(elementName);
                        if (index !== -1) {
                            selectedCollections.splice(index, 1);
                        }
                    }
                break;
                case "selectedSeasons":
                    if(this.checked){
                        selectedSeasons.push(elementName)
                    } else {
                        var index = selectedSeasons.indexOf(elementName);
                        if (index !== -1) {
                            selectedSeasons.splice(index, 1);
                        }
                    }
                    break;
                case "selectedColours":
                    if(this.checked){
                        selectedColours.push(elementName)
                    } else {
                        var index = selectedColours.indexOf(elementName);
                        if (index !== -1) {
                            selectedColours.splice(index, 1);
                        }
                    }
                break;
            }
        }
    });
}

const filterCall = () => {
    let checkboxes = [...document.querySelectorAll(".filterCheckbox")];
    checked = false;
    // check if any filters
    checkboxes.forEach((checkbox) => { 
        if(checkbox.checked){
            checked = true
        } 
    });
    // if filter use filters to make call
    // else regular call with unfiltered params
    // console.log(selectedPrices);
    if(checked == true){
        makeCall(1,ajaxType, searchbar.value, selectedSizes, selectedPrices, selectedCollections, selectedColours);
    } else {
        makeCall(0, ajaxType, searchbar.value, sizes, prices, collections, colours);
    }
}



filterButton.onclick = function(){
    filterCall();
    resetPriceRange();
}

mobileClose.onclick = function(){
    mobileToggle();
    filterCall();
}

const mobileToggle = () => {
    let mobileOpen = document.querySelectorAll(".search-filter__filter--children-mobile")[0],
    mobileClose = document.querySelectorAll(".search-filter__mobile-close")[0];
    mobileOpen.addEventListener("click", ()=>{
        mobileMenu.classList.add("mobileActive")
        document.body.classList.add('stopScrolling')
        handleMobileUi();
    });
    mobileClose.addEventListener("click", ()=>{
        mobileMenu.classList.remove("mobileActive")
        document.body.classList.remove('stopScrolling')
    });
    
}


const sortByFunc = (sortType, arr) => {
    switch(sortType){
        case "PriceDesc": 
        return arr.sort((a,b) => Number(b.fields.price.replace(/[^0-9.-]+/g,"")) - Number(a.fields.price.replace(/[^0-9.-]+/g,"")));
        break;
        case "PriceAsc":
         return arr.sort((a,b) => Number(a.fields.price.replace(/[^0-9.-]+/g,"")) - Number(b.fields.price.replace(/[^0-9.-]+/g,"")));
        break;       
        case "AZ": 
        return arr.sort((a, b) => a.fields.name.localeCompare(b.fields.name))
        break;
        case "ZA": 
        return arr.sort((a, b) => b.fields.name.localeCompare(a.fields.name))
        break;
     }

 }

 let sortBy = document.getElementById("SortByFilter");
    sortBy.onchange = function(){
        let sortType = sortBy.value;
        products = sortByFunc(sortType, products)

        populateProducts(products, ajaxType)
};
var priceRange1 = false;
var priceRange2 = false;
var priceRange3 = false;
var priceRange4 = false;
var priceRange5 = false;

const resetPriceRange = () => {
    priceRange1 = false;
    priceRange2 = false;
    priceRange3 = false;
    priceRange4 = false;
    priceRange5 = false;
}
const createPriceCheckboxes = (price, parent, type) => {
    let p = Number(price.replace(/[^0-9.-]+/g,""))
    console.log(parent)
    if( p > 0 && p <= 49 && priceRange1 == false){
        priceRange1 = true
        let filtersString = `<div class = "filterOptionBox" data-type = ${type}> <label class="checkbox terms"><input type = "checkbox" name = "${type}" class = "filterCheckbox"> <span class="icon"></span>£0 - £49</label></div>`
        parent.insertAdjacentHTML("beforeend", filtersString)
    }
    if( p >= 50 && p <= 99 && priceRange2 == false){
        priceRange2 = true
        let filtersString = `<div class = "filterOptionBox" data-type = ${type}> <label class="checkbox terms"><input type = "checkbox" name = "${type}" class = "filterCheckbox"> <span class="icon"></span>£50 - £99</label></div>`
        parent.insertAdjacentHTML("beforeend", filtersString)
    }
    if( p >= 100 && p <= 250 && priceRange3 == false){
        priceRange3 = true
        let filtersString = `<div class = "filterOptionBox" data-type = ${type}> <label class="checkbox terms"><input type = "checkbox" name = "${type}" class = "filterCheckbox"> <span class="icon"></span>£100 - £250</label></div>`
        parent.insertAdjacentHTML("beforeend", filtersString)
    }
    if( p >= 251 && p <= 500 && priceRange4 == false){
        
        priceRange4 = true
        let filtersString = `<div class = "filterOptionBox" data-type = ${type}> <label class="checkbox terms"><input type = "checkbox" name = "${type}" class = "filterCheckbox"> <span class="icon"></span>£251 - £500</label></div>`
        parent.insertAdjacentHTML("beforeend", filtersString)
        console.log(parent)

    }
    if( p > 500 && priceRange5 == false){
        priceRange5 = true
        let filtersString = `<div class = "filterOptionBox" data-type = ${type}> <label class="checkbox terms"><input type = "checkbox" name = "${type}" class = "filterCheckbox"> <span class="icon"></span>£500+</label></div>`
        parent.insertAdjacentHTML("beforeend", filtersString)

    }
    };

    
}

function arraysIdentical(arr1, arr2) {
    var i = arr1.length;
    if (i !== arr2.length) {
        return false;
    }
    while (i--) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
}

function indexOfArr(arr, val, comparer) {
    for (var i = 0, len = arr.length; i < len; ++i) {
        if ( i in arr && comparer(arr[i], val) ) {
            return i;
        }
    }
    return -1;
}
