/**
 * Eskimo
 */

var Eskimo = function() {
    'use strict';

    function init()
    {
        if ($('.cms-notice .section *').length) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".cms-notice").offset().top - 100
            }, 300);
        }
    }

    /**
     * Reset the form inputs
     */
    function resetForm(form) {
        form.find(':input').not(':button, :submit, :reset, :hidden, :checkbox, :radio').val('');
        form.find(':checkbox, :radio').prop('checked', false);
    }

    /**
     * Show the error message
     * @param string message 
     */
    function showError(form, message) {

        var errorElement = form.find('.js-form-error');
        var successElement = form.find('.js-form-success');

        errorElement.slideDown(200, function () {
            errorElement.html(message);
        });
    }

    /**
     * Show the success message
     * @param string message 
     */
    function showSuccess(form, message) {

        var errorElement = form.find('.js-form-error');
        var successElement = form.find('.js-form-success');

        errorElement.slideUp().html();

        successElement.slideDown(200, function () {
            successElement.html(message);
        });
    }

    return {
        showError: showError,
        showSuccess: showSuccess,
        resetForm: resetForm,
        init: init
    };

}();

export default Eskimo;