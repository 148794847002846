/**
 * Order
 * Author: Maiko McElroy
 */

var Order = (function() {
    'use strict';

	var options = {
        changeDelivery: $('.js-change-delivery-address'),
        order: $('.trade.order')
    };

	function init(params)
    {
        if(options.changeDelivery.length){
            initChangeDelivery();
        }
    }

    function initChangeDelivery()
    {
        options.changeDelivery.magnificPopup({
            type: 'ajax',
            modal: true,
            ajax:{
                settings: {
                    url: '/js/xhr/trade/getDeliveryAddresses.php',
                }
            },
            callbacks: {
                ajaxContentAdded: function(){
                    bindAddressPopup(this.content);
                }
            } 
        });
    }

    function bindAddressPopup(content)
    {
        content.find('.form-buttons .close').click(function(e){
            e.preventDefault();
            $.magnificPopup.close();
        });
        content.find('.js-dropkick').dropkick({ mobile: true });
        content.find('form').submit(function(e){
            e.preventDefault();
            changeDeliveryAddress($(this));
        });
    }

    function changeDeliveryAddress(form)
    {
        let data = form.serializeArray();
        data.push({name: 'order', value: options.order.data('id')});
        $.ajax({
            url: '/js/xhr/trade/changeDeliveryAddress.php',
            method: 'POST',
            data: data
        }).done(function(data){
            location.reload();
        });
    }

    return {
    	init: init
    };

})();

export default Order;