/*!
 * @package Validation
 * @author William Walker <william@williamwalker.me> (http://williamwalker.me/)
 * @version 2.0.0
 * Build date: 2014-11-15 19:29:34 GMT
 *
 * A general purpose validation library
 */

/* exported Validation */
var Validation = function() {
	/**
	 * Validates a required field
	 *
	 * @param  Object $el The HTML element as a jQuery object
	 * @return Bool       Valid or invalid
	 */
	function _validateRequired($el) {
		if($el.length > 1) {
			// It's a checkbox or radio button group
			var numChecked = 0;

			$el.each(function(index, el) {
				if($(el).is(':checked')) {
					numChecked++;
				}
			});

			if (numChecked >= 1) {
				return true;
			}

			return false;
		}

		if($el.attr('type') === 'radio' || $el.attr('type') === 'checkbox') {
			// It's a single checkbox or radio button
			if($el.is(':checked')) {
				return true;
			}

			return false;
		}

		// It'll be a select, textarea, email, number field etc
		if($.trim($el.val()) === '') {
			return false;
		}

		return true;
	}

	/**
	 * Validates a name - allows spaces and hyphens for double barrel names
	 *
	 * @param  String v The field value
	 * @return Bool     Valid or invalid
	 */
	function _validateName(v) {
		return /^[A-z'\- ]+$/i.test(v);
	}

	/**
	 * Validates a phone number - allows spaces, hyphens, plus symbols and brackets (international numbers)
	 *
	 * @param  String v The field value
	 * @return Bool     Valid or invalid
	 */
	function _validatePhoneNumber(v) {
		return /^[0-9 +\-\)\(]+$/i.test(v);
	}

	/**
	 * Validates an email address
	 *
	 * @param  String v The field value
	 * @return Bool     Valid or invalid
	 */
	function _validateEmail(v) {
		var emailRegEx = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
		return emailRegEx.test(v);
	}

	/**
	 * Validates a postcode
	 *
	 * @param  Object $el The HTML element as a jQuery object
	 * @return Bool       Valid or invalid
	 */
	function _validatePostcode($el) {
		var value       = $el.val();
		var countryCode = $el.data('iso-alpha2').toUpperCase();
		var isValid     = false;

		switch (countryCode) {
			case 'BR':
				isValid = /^(\d{2})([\.]?)(\d{3})([\-]?)(\d{3})$/.test(value);
				break;

			case 'CA':
				isValid = /^(?:A|B|C|E|G|H|J|K|L|M|N|P|R|S|T|V|X|Y){1}[0-9]{1}(?:A|B|C|E|G|H|J|K|L|M|N|P|R|S|T|V|W|X|Y|Z){1}\s?[0-9]{1}(?:A|B|C|E|G|H|J|K|L|M|N|P|R|S|T|V|W|X|Y|Z){1}[0-9]{1}$/i.test(value);
				break;

			case 'CZ':
				isValid = /^(\d{3})([ ]?)(\d{2})$/.test(value);
				break;

			case 'DK':
				isValid = /^(DK(-|\s)?)?\d{4}$/i.test(value);
				break;

			case 'GB':
				isValid = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]? ?[0-9][A-Z]{2}$/i.test(value);
				break;

			case 'IT':
				isValid = /^(I-|IT-)?\d{5}$/i.test(value);
				break;

			case 'MA':
				isValid = /^[1-9][0-9]{4}$/i.test(value);
				break;

			case 'NL':
				isValid = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(value);
				break;

			case 'RO':
				isValid = /^(0[1-8]{1}|[1-9]{1}[0-5]{1})?[0-9]{4}$/i.test(value);
				break;

			case 'RU':
				isValid = /^[0-9]{6}$/i.test(value);
				break;

			case 'SE':
				isValid = /^(S-)?\d{3}\s?\d{2}$/i.test(value);
				break;

			case 'SG':
				isValid = /^([0][1-9]|[1-6][0-9]|[7]([0-3]|[5-9])|[8][0-2])(\d{4})$/i.test(value);
				break;

			case 'SK':
				isValid = /^(\d{3})([ ]?)(\d{2})$/.test(value);
				break;

			case 'US':
				isValid = /^\d{4,5}([\-]?\d{4})?$/.test(value);
				break;

			default:
				// Since we can't match/don't support the country, don't validate it - just make sureit's not empty
				isValid = _validateRequired($el);
				break;
		}

		return isValid;
	}

	/**
	 * Validates a field against another to ensure they match
	 *
	 * @param  Object $el The HTML element as a jQuery object
	 * @return Bool       Valid or invalid
	 */
	function _validateIdentical($el) {
		var value1 = $el.val();
		var fieldName = $el.data('identical-with');

		var $comparisonField = $el.closest('form').find('input[name="'+fieldName+'"], select[name="'+fieldName+'"], textarea[name="'+fieldName+'"]');

		if(!$comparisonField.length || $comparisonField === null) {
			return false;
		}

		if(value1 === $comparisonField.val()) {
			return true;
		}

		return false;
	}

	/**
	 * Validates a number to ensure it is between an upper and lower limit
	 *
	 * @param  Object $el The HTML element as a jQuery object
	 * @return Bool       Valid or invalid
	 */
	function _validateBetween($el) {
		var value      = parseFloat($el.val());
		var lowerLimit = parseFloat($el.data('lower-limit'));
		var upperLimit = parseFloat($el.data('upper-limit'));
		var inclusive  = $el.data('inclusive');

		if(inclusive) {
			return value >= lowerLimit && value <= upperLimit;
		} else {
			return value > lowerLimit && value < upperLimit;
		}
	}

	/**
	 * Validates a number to ensure it is less than an upper limit
	 *
	 * @param  Object $el The HTML element as a jQuery object
	 * @return Bool       Valid or invalid
	 */
	function _validateLessThan($el) {
		var value     = parseFloat($el.val());
		var limit     = parseFloat($el.data('upper-limit'));
		var inclusive = $el.data('inclusive');

		if(inclusive) {
			return value <= limit;
		} else {
			return value < limit;
		}
	}

	/**
	 * Validates a number to ensure it is above a lower limit
	 *
	 * @param  Object $el The HTML element as a jQuery object
	 * @return Bool       Valid or invalid
	 */
	function _validateGreaterThan($el) {
		var value     = parseFloat($el.val());
		var limit     = parseFloat($el.data('lower-limit'));
		var inclusive = $el.data('inclusive');

		if(inclusive) {
			return value >= limit;
		} else {
			return value > limit;
		}
	}

	/**
	 * Validates a number only
	 *
	 * @param  String v The field value
	 * @return Bool     Valid or invalid
	 */
	function _validateDigit(v) {
		return /^\d+$/.test(v);
	}

	/**
	 * Validates a min length
	 *
	 * @param  String v The field value
	 * @return Bool     Valid or invalid
	 */
	function _validateMinLength($el) {
		let min = $el.data('min-length');
		return ($el.val().length >= min);
	}

	/**
	 * Validates positive, negative and decimal numbers
	 *
	 * @param  String v The field value
	 * @return Bool     Valid or invalid
	 */
	function _validateNumeric(v) {
		return !isNaN(parseFloat(v)) && isFinite(v);
	}

	/**
	 * Validates a number, without leading zeros
	 *
	 * @param  String v The field value
	 * @return Bool     Valid or invalid
	 */
	function _validateInteger(v) {
		return /^(?:-?(?:0|[1-9][0-9]*))$/.test(v);
	}

	/**
	 * Validates a hexadecimal number
	 *
	 * @param  String v The field value
	 * @return Bool     Valid or invalid
	 */
	function _validateHexNumber(v) {
		return /^[0-9a-fA-F]+$/.test(v);
	}

	/**
	 * Validates a hex colour code
	 *
	 * @param  Object $el The HTML input as a jQuery object
	 * @return Bool       Valid or invalid
	 */
	function _validateHexColour($el) {
		if($el.attr('type') === 'color') {
			// It's an HTML5 color input so only accept a 6 character value to match the spec
			return /^#[0-9A-F]{6}$/i.test($el.val());
		}

        return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test($el.val());
	}

	/**
	 * Validates an IP address, both V4 and V6
	 *
	 * @param  Object $el The HTML input as a jQuery object
	 * @return Bool       Valid or invalid
	 */
	function _validateIPAddress($el) {
		var ipv4Regex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
		var ipv6Regex = /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;

		if($el.data('ip-version') === undefined) {
			// A version hasn't been specified so match against both v4 and v6
			if(ipv4Regex.test($el.val()) || ipv6Regex.test($el.val())) {
				return true;
			}

			return false;
		} else {
			if($el.data('ip-version') === 4) {
				return ipv4Regex.test($el.val());
			} else if($el.data('ip-version') === 6) {
				return ipv6Regex.test($el.val());
			}

			return false;
		}

		return false;
	}

	/**
	 * Validates a URL
	 *
	 * @param  String v The field value
	 * @return Bool     Valid or invalid
	 */
	function _validateUrl(v) {
		// Credit to https://gist.github.com/dperini/729294
		//
		// Regular Expression for URL validation
		//
		// Author: Diego Perini
		// Updated: 2010/12/05
		//
		// the regular expression composed & commented
		// could be easily tweaked for RFC compliance,
		// it was expressly modified to fit & satisfy
		// these test for an URL shortener:
		//
		//   http://mathiasbynens.be/demo/url-regex
		//
		// Notes on possible differences from a standard/generic validation:
		//
		// - utf-8 char class take in consideration the full Unicode range
		// - TLDs are mandatory unless `allowLocal` is true
		// - protocols have been restricted to ftp, http and https only as requested
		//
		// Changes:
		//
		// - IP address dotted notation validation, range: 1.0.0.0 - 223.255.255.255
		//   first and last IP address of each class is considered invalid
		//   (since they are broadcast/network addresses)
		//
		// - Added exclusion of private, reserved and/or local networks ranges
		//   unless `allowLocal` is true
		//
		// - Added possibility of choosing a custom protocol
		//
		var urlRegex = new RegExp(
			"^" +
			// protocol identifier
			"(?:(?:https?|ftp|ssh)://)" +
			// user:pass authentication
			"(?:\\S+(?::\\S*)?@)?" +
			"(?:" +
			// IP address exclusion
			// private & local networks
			"(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
			"(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
			"(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
			// IP address dotted notation octets
			// excludes loopback network 0.0.0.0
			// excludes reserved space >= 224.0.0.0
			// excludes network & broacast addresses
			// (first & last IP address of each class)
			"(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
			"(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
			"(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
			"|" +
			// host name
			"(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
			// domain name
			"(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
			// TLD identifier
			"(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
			")" +
			// port number
			"(?::\\d{2,5})?" +
			// resource path
			"(?:/\\S*)?" +
			"$", "i"
		);

		return urlRegex.test(v);
	}

	/**
	 * Validates a Universally Unique Identifier
	 *
	 * @param  Object $el The HTML input as a jQuery object
	 * @return Bool       Valid or invalid
	 */
	function _validateUUID($el) {
		var v3Regex = /^[0-9A-F]{8}-[0-9A-F]{4}-3[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i;
		var v4Regex = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
		var v5Regex = /^[0-9A-F]{8}-[0-9A-F]{4}-5[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
		var anyVersionRegex = /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i;

		if($el.data('uuid-version') === undefined) {
			// A version hasn't been specified so match against both any version
			return anyVersionRegex.test($el.val());
		} else {
			if($el.data('uuid-version') === 3) {
				return v3Regex.test($el.val());
			} else if($el.data('uuid-version') === 4) {
				return v4Regex.test($el.val());
			} else if($el.data('uuid-version') === 5) {
				return v5Regex.test($el.val());
			}

			return false;
		}

		return false;
	}

	/**
	 * Validates a credit card number
	 *
	 * @param  String v The field value
	 * @return Bool     Valid or invalid
	 */
	function _validateCreditCard(v) {
		// Accept only digits, dashes or spaces
		if (/[^0-9-\s]+/.test(v)) {
			return false;
		}

		// Strip out the spaces
		v = v.replace(/\D/g, '');

		// Validate the card number based on prefix (IIN ranges) and length
		var cards = {
			americanExpress: {
				length: [15],
				prefix: ['34', '37']
			},
			dinersClub: {
				length: [14],
				prefix: ['300', '301', '302', '303', '304', '305', '36']
			},
			dinersClubUS: {
				length: [16],
				prefix: ['54', '55']
			},
			discover: {
				length: [16],
				prefix: [
					'6011', '622126', '622127', '622128', '622129', '62213',
					'62214', '62215', '62216', '62217', '62218', '62219',
					'6222', '6223', '6224', '6225', '6226', '6227', '6228',
					'62290', '62291', '622920', '622921', '622922', '622923',
					'622924', '622925', '644', '645', '646', '647', '648',
					'649', '65'
				]
			},
			jcb: {
				length: [16],
				prefix: ['3528', '3529', '353', '354', '355', '356', '357', '358']
			},
			laser: {
				length: [16, 17, 18, 19],
				prefix: ['6304', '6706', '6771', '6709']
			},
			maestro: {
				length: [12, 13, 14, 15, 16, 17, 18, 19],
				prefix: [
					'5018', '5020', '5038', '6304', '6759', '6761', '6762',
					'6763', '6764', '6765', '6766'
				]
			},
			mastercard: {
				length: [16],
				prefix: ['51', '52', '53', '54', '55']
			},
			solo: {
				length: [16, 18, 19],
				prefix: ['6334', '6767']
			},
			unionpay: {
				length: [16, 17, 18, 19],
				prefix: [
					'622126', '622127', '622128', '622129', '62213', '62214',
					'62215', '62216', '62217', '62218', '62219', '6222', '6223',
					'6224', '6225', '6226', '6227', '6228', '62290', '62291',
					'622920', '622921', '622922', '622923', '622924', '622925'
				]
			},
			visa: {
				length: [16],
				prefix: ['4']
			}
		};

		for (var i = 0, cl = Object.keys(cards).length; i < cl; i++) {
			var cardType = cards[Object.keys(cards)[i]];

			for (var j = 0, cpl = cardType.prefix.length; j < cpl; j++) {
				// Check the length and prefix
				if (v.substr(0, cardType.prefix[j].length) === cardType.prefix[j] && $.inArray(v.length, cardType.length) !== -1) {
					return true;
				}
			}
		}

		return false;
	}

	/**
	 * Validates a simple maths based spam field
	 *
	 * @param  String v  The field value/users answer
	 * @param  String x1 The first operand
	 * @param  String x2 The second operand
	 * @return Bool      Valid or invalid
	 */
	function _validateSpam(v, x1, x2) {
		if(parseInt(v) !== (parseInt(x1) + parseInt(x2))) {
			return false;
		}

		return true;
	}

	/**
	 * The main for validation method - loops over each form input and validates it accordingly
	 *
	 * @param  Object $form   The form to be validated as a jQuery object
	 * @param  Bool   verbose Whether to use verbose error reporting - true = error messages and indicator shown, false = error indicator to be shown
	 * @return Bool           Valid or invalid
	 */
	function validateForm($form, verbose) {
		var errors  = 0,
			elCache = [];

		verbose = typeof verbose !== 'undefined' ? verbose : false;

		$form.find("input[type!='hidden'], textarea, select").each(function() {
			var $this  = $(this),
				$error = $this.next('.error-message');

			$this.removeClass('error');

			if($this.prop('tagName') == 'SELECT'){
				if($this.prev().hasClass('dk-select')){
					$this.prev().removeClass('error');
				}
			}

			// Check if this is a disabled field
			if($this.attr('disabled') === 'disabled') {
				return;
			}

			if($this.hasClass('val-required')) {
				if($this.attr('type') === 'radio' || $this.attr('type') === 'checkbox') {
					// Since it's a radio or checkbox, get all of them in the group
					var elName = $this.attr('name');
					var $els   = $this.closest('form').find('input[name="' + elName + '"]');

					if($els.length > 1) {
						$this = $els;

						// We're validating them all at once so cache them so we don't do them all over again for each individual input
						if(!elCache[elName]) {
							elCache[elName] = $this;
						} else {
							// We've done this group already, so skip it
							return;
						}
					}
				}

				if(!_validateRequired($this)) {
					errors += 1;

					if($this.length > 1) {
						$this.each(function(index, el) {
							addErrorClass($el);
						});
					} else {
						addErrorClass($this);
					}

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-identical')) {
				if(!_validateIdentical($this)) {
					errors += 1;
					addErrorClass($this, 'identical');

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-name')) {
				if(!_validateName($this.val())) {
					errors += 1;
					addErrorClass($this);

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-tel')) {
				if(!_validatePhoneNumber($this.val())) {
					errors += 1;
					addErrorClass($this);

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-email')) {
				if(!_validateEmail($this.val())) {
					errors += 1;
					addErrorClass($this);

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-postcode')) {
				if(!_validatePostcode($this)) {
					errors += 1;
					addErrorClass($this);

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-digit')) {
				if(!_validateDigit($this.val())) {
					errors += 1;
					addErrorClass($this);

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-between')) {
				if(!_validateBetween($this)) {
					errors += 1;
					addErrorClass($this);

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-less-than')) {
				if(!_validateLessThan($this)) {
					errors += 1;
					addErrorClass($this);

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-greater-than')) {
				if(!_validateGreaterThan($this)) {
					errors += 1;
					addErrorClass($this);

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-numeric')) {
				if(!_validateNumeric($this.val())) {
					errors += 1;
					addErrorClass($this);

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-integer')) {
				if(!_validateInteger($this.val())) {
					errors += 1;
					addErrorClass($this);

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-hex-number')) {
				if(!_validateHexNumber($this.val())) {
					errors += 1;
					addErrorClass($this);

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-hex-colour')) {
				if(!_validateHexColour($this)) {
					errors += 1;
					addErrorClass($this);

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-ip-address')) {
				if(!_validateIPAddress($this)) {
					errors += 1;
					addErrorClass($this);

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-url')) {
				if(!_validateUrl($this.val())) {
					errors += 1;
					addErrorClass($this);

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-min-length')) {
				if(!_validateMinLength($this)) {
					errors += 1;
					addErrorClass($this, 'length');

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-uuid')) {
				if(!_validateUUID($this)) {
					errors += 1;
					addErrorClass($this);

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-credit-card')) {
				if(!_validateCreditCard($this.val())) {
					errors += 1;
					addErrorClass($this);

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}

			if($this.hasClass('val-spam')) {
				var $label = $this.prevAll('label');

				if(!_validateSpam($this.val(), $label.find('span.val-x1').text(), $label.find('span.val-x2').text())) {
					errors += 1;
					addErrorClass($this);

					if(verbose) {
						$error.text($error.data('error-message')).show();
					}
				}
			}
		});

		if(errors > 0) {
			return false;
		}

		return true;
	}

	function addErrorClass($elem, type = null)
	{
		$elem.addClass('error');

		// get field name and add message
		if($elem.prev().is( 'label' )) {
			var name = $elem.prev().text();
			var message = "Please enter " + name.replace('*','');

			if (type != null) {

				if(type === 'identical') {
					message = name.replace('*','') + ' must match';
					$elem.val("");
					$elem.parent().next().find("input").val("");
				}

				if(type === 'length') {
					var min = $elem.data('min-length');
					message = 'minimum length must be ' + min + ' characters';
				}
				
			}
			
			$elem.attr("placeholder", message);
		}
		

		if($elem.prop('tagName') == 'SELECT'){
			if($elem.prev().hasClass('dk-select')){
				$elem.prev().addClass('error');
			}
		}
	}

	// return pointers to public methods and properties you want to reveal
	return {
		validateForm: validateForm
	};
}();

export default Validation;