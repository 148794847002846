if(document.getElementById("mapid")){
    console.log("[Stockists] init")
    let markers = [],
    initPosition = {},
    sortedStockists = [],
    stockists,
    southWest = L.latLng(-89.98155760646617, -180),
    northEast = L.latLng(89.99346179538875, 180),
    bounds = L.latLngBounds(southWest, northEast),
    currentLocation = [],
    countryArr = [],
    countries,
    parent,
    form,
    address,
    input,
    useCurrent,
    zoomTrigger,
    mymap,
    stockistCount,
    markersLayer = new L.LayerGroup(),
    countryid,
    boundaryStyle = {
        "color": "transparent",
        "weight": 0,
        "opacity": 0
    };
    var mq = window.matchMedia( "(min-width: 600px)" );
    if(mq.matches){
        console.log("Desktop")
        countries = document.getElementById("countries"),
        parent = document.getElementById("stockist-output"),
        form = document.getElementById('addressForm'),
        address = document.getElementById("addressOutput"),
        input = form.querySelector('input[type="text"]'),
        useCurrent = document.getElementById("useLocation"),
        zoomTrigger = document.querySelectorAll(".stockists-page__list--item"),
        stockistCount = document.querySelector(".stockists-page__filter--count"),
        countryid = "#countries",
        mymap = L.map('mapid').setView([51.505, -0.09], 9).setMaxBounds(bounds);
    } else{
        console.log("Mobile")
        countries = document.getElementById("countries-mobile"),
        parent = document.getElementById("stockist-output-mobile"),
        form = document.getElementById('addressForm-mobile'),
        address = document.getElementById("addressOutput-mobile"),
        input = form.querySelector('input[type="text"]'),
        useCurrent = document.getElementById("useLocation-mobile"),
        zoomTrigger = document.querySelectorAll(".stockists-page__list--item"),
        stockistCount = document.querySelector(".stockists-page__mobile-filter--count-mobile"),
        countryid = "#countries-mobile",
        mymap = L.map('mapidmobile').setView([51.505, -0.09], 9).setMaxBounds(bounds);
    }
    var geojsonLayer = new L.GeoJSON.AJAX("countries.geo.json",{
        style: boundaryStyle
    });     
    geojsonLayer.addTo(mymap);
    const panToCountry = (mymap, val) =>{
        if(val === "ALL"){
            // mymap.fitBounds(bounds);
        }
        let string = val.toUpperCase();
        geojsonLayer.eachLayer(function (layer) {
        let substring = layer.feature.properties.name.toUpperCase()
            if (string.includes(substring)) {
              mymap.fitBounds(layer.getBounds());
            }
          });
      
    }
  
    function initGeolocation(){
        if( navigator.geolocation )
        {
        navigator.geolocation.getCurrentPosition( success, fail );
        address.innerHTML = "Using your current location"
        }
        else
        {
        alert("Sorry, your browser does not support geolocation services.");
        }
    }

    function success(position){
        console.log("Location success")

        initPosition.long =  position.coords.longitude;
        initPosition.lat =  position.coords.latitude;
        sortStockists(initPosition.long, initPosition.lat ,stockists, false, false);
        mymap.flyTo({lat:  initPosition.lat, lng: initPosition.long} , 10)
    }

    function fail()
    {
        console.log("Location failed")
        
        initPosition.long =  "-0.1103251";
        initPosition.lat =  "51.5288434";
        sortStockists(initPosition.long, initPosition.lat ,stockists, false, true);
        mymap.flyTo({lat:  initPosition.lat, lng: initPosition.long} , 10)
    }

    function loadStockists() {  
        $.ajax({
            async: false,
            method: "POST",
            url: 'js/xhr/getStockists.php',
                success:function(data) {
                    data = JSON.parse(data)
                    stockists = data;
                },
                error: function(){
                console.log("Error")
            }
        });
    }
    useCurrent.addEventListener("click", function(){
        initGeolocation();
    });
    const filterByForm = async () => {
        countries.selectedIndex = 0
        let enteredLong,
            enteredLat;
        const provider = new GeoSearch.OpenStreetMapProvider();
            const search = new GeoSearch.GeoSearchControl({
            provider:provider,
        
            });
            const results = await provider.search({ 
                query: input.value,
                
            })
            enteredLong = results[0].x,
            enteredLat = results[0].y
            address.innerHTML = results[0].label
            mymap.flyTo({lat:  enteredLat, lng: enteredLong} , 10)
        // ADD TO SORT STOCKISTS, HOPEFULLY EVERYTHING FALLS THROUGH FROM THERE  
        sortStockists(enteredLong, enteredLat, stockists, true, false)
        input.value = ""
        useCurrent.style.opacity ="1";
        useCurrent.style.width = "10rem"
    }

    form.addEventListener('submit',(e) => {
        e.preventDefault();
        filterByForm();
        
    });
    useCurrent.addEventListener("click", function(){
        this.style.opacity = "0"
        this.style.width = "0px"
    });
    const sortStockists = (long, lat, stockists, filtered, defaultLocation) =>{
           
            sortedStockists = [];
            if(currentLocation.length > 0){
                currentLocation[0].remove(mymap)
                currentLocation = []
            }
            
            var currentIcon = L.icon({
                iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
                shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
                });
            let currentMarker = L.marker([lat, long], {icon: currentIcon})
            if(filtered){
                currentMarker.bindPopup("Your searched location");
            }else if(defaultLocation) {
                currentMarker.bindPopup("Your current location could not be determined! <br> Why not check out WallaceSewells London Store");
            }else{
                currentMarker.bindPopup("Current Location");
            }
            
            
            currentLocation.push(currentMarker);
            currentLocation[0].addTo(mymap).openPopup();
        for (const stockist of stockists){
            if(stockist.fields.latitude != null && stockist.fields.latitude != null){
                let distance = calcCrow(lat, long, stockist.fields.latitude, stockist.fields.longitude);
                stockist.fields.distance = distance
                sortedStockists.push(stockist);
            }
        }
        sortedStockists = sortedStockists.sort((a, b) => {
            return Math.abs(1-a.fields.distance) - Math.abs(1-b.fields.distance);
        });
        if(filtered === false){
        
            for (const stockist of sortedStockists){
                // ADD THE DROPDOWNS TO SELECT
                var option = document.createElement("option");
                option.text = stockist.fields.countryName;
                option.value = stockist.fields.countryName;
                if(!$(`${countryid} option[value='${stockist.fields.countryName}']`).length > 0){
                    countries.appendChild(option);
                }
            }
        }
        initMap();
    }

    function calcCrow(lat1, lon1, lat2, lon2) 
    {
    var R = 6371; // km
    var dLat = toRad(lat2-lat1);
    var dLon = toRad(lon2-lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c;
    return d;
    }

    // Converts numeric degrees to radians
    function toRad(Value) 
    {
        return Value * Math.PI / 180;
    }

    function initMap(){
        
        L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        minZoom: 2.5,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        noWrap: true,
        maxBounds: bounds,
        // REMOVE THIS BEFORE GOING LIVE
        accessToken: 'pk.eyJ1IjoiY29sZG93b2xkbyIsImEiOiJjbGRuMW9ndW8wZGNuM3BxZ3lubDBpcDY2In0.F4wvY9HNSnvS1dfNSP9niA'
    }).addTo(mymap);
            renderIcons(mymap, "ALL")
    }
    function init(){ 
        initGeolocation();
        loadStockists();
    }
    init();
    const renderIcons = (mymap, val) =>{
        markersLayer.clearLayers();
        markers = [];
        parent.innerHTML = "";
        let renderedStockists = [];
        
      
        
        for (const stockist of sortedStockists){
            let markerString = "Name: " + stockist.fields.stockistName + "<br> Country: " + stockist.fields.countryName +"<br> Distance: " + Math.round((stockist.fields.distance / 1.609  ) * 100) / 100 + " miles "  + "<br> Website: <a href = "+ stockist.fields.website +" target = '_blank'>"+ stockist.fields.website +"</a>"
            if(val == "ALL"){
                let marker = L.marker([stockist.fields.latitude, stockist.fields.longitude]);
                marker.bindPopup(markerString);
                markers[`${stockist.fields.stockistName}`] = marker
                renderedStockists.push(stockist)
                markersLayer.addLayer(marker)
                panToCountry(mymap, val)
            } else if(stockist.fields.countryName == val){
                let marker = L.marker([stockist.fields.latitude, stockist.fields.longitude]);
                marker.bindPopup(markerString);
                markers[`${stockist.fields.stockistName}`] = marker
                renderedStockists.push(stockist)
                markersLayer.addLayer(marker)
                panToCountry(mymap, val)
            } 
        }
        for (const stockist of renderedStockists){
        let dataName = stockist.fields.stockistName,
            dataAdd1 = stockist.fields.addressLine1,
            dataAdd2 = stockist.fields.addressLine2,
            dataTown = stockist.fields.town,
            dataPost = stockist.fields.postCode,
            dataCountry = stockist.fields.countryName,
            dataDistance = Math.round((stockist.fields.distance / 1.609  ) * 100) / 100,
            dataWeb = stockist.fields.website;
            let dataString = `<div class = "stockists-page__list--item" data-zoom = "${stockist.fields.stockistName}">
                                    <div class = "stockists-page__list--item-inner">
                                        
                                        ${dataName ? `<h3>${dataName}</h3>` : `<h3></h3>`}
                                        ${dataAdd1 ? `<p>${dataAdd1}</p>` : `<p></p>`}
                                        ${dataAdd2 ? `<p>${dataAdd2}</p>` : `<p></p>`}
                                        ${dataTown ? `<p>${dataTown}</p>` : `<p></p>`}
                                        ${dataPost ? `<p>${dataPost}</p>` : `<p></p>`}
                                        ${dataCountry ? `<p>${dataCountry}</p>` : `<p></p>`}
                                        ${dataDistance ? `<p>${dataDistance} miles from location</p>` : `<p></p>`}
                                        ${dataWeb ? ` <a href="${dataWeb}">View Website →</a>` : `<p></p>`}
                                    </div>
                                </div>`;
                    parent.insertAdjacentHTML('beforeend', dataString)
        }
        zoomTrigger = document.querySelectorAll(".stockists-page__list--item"),
        stockistCount.innerHTML = `${Object.keys(markers).length} STOCKIST${Object.keys(markers).length > 1 ?"S" : ""}`
        flyToParent()
        markersLayer.addTo(mymap);
    }
    countries.addEventListener("change", function(){

        renderIcons(mymap, this.value)
    });



    const flyToParent = () => {
        
        for (const zoom of zoomTrigger){
            zoom.addEventListener("click", function(){
                let filter = Array.prototype.slice.call(zoomTrigger).filter(x => x != zoom);
                for (const x of filter){
                    x.classList.remove("selectedStock")
                }
                this.classList.add("selectedStock")
                // if(this.classList.contains("selectedStock")){
                //     this.classList.add("selectedStock")
                // }else {
                //     this.classList.remove("selectedStock")
                // }
                
                let icon = this.getAttribute('data-zoom')
                let marker = markers[icon],
                latlng = marker.getLatLng();
                mymap.flyTo(latlng, 15)
                marker.openPopup();
            })
        }
    }
   
    window.addEventListener("DOMContentLoaded", function(){
       
        for (const zoom of zoomTrigger){
            zoom.addEventListener("click", function(){
                flyToParent()
            })
        }
    });
}