
import axios from "axios";

  let promoBTN = document.querySelectorAll(".promo-button")[0];
	const errOut = document.getElementById("discountError");
  if(promoBTN){
	promoBTN.onclick = function(e){
		let promoCode = document.getElementById("code");
		e.preventDefault();
		$.ajax({
        async: false,
        method: "POST",
		data: {
			promoCode: promoCode.value
		},
        url: '/js/xhr/checkPromo.php',
            success:function(data) {
				      populateCosts(data);
			   
            },
            error: function(err){
                console.log("Error: " + err);
        }
    });
	}
  giftWrapped.addEventListener("change", function(){
    if($("#giftWrapped").prop("checked") == true){
      // Show
      document.getElementById("giftOrderMessage").classList.remove("inactive")
      document.getElementById("giftLabel").classList.remove("inactive")
    }else{
      // Hide
      document.getElementById("giftOrderMessage").classList.add("inactive")
      document.getElementById("giftLabel").classList.add("inactive")
    }
  });
	const populateCosts = (data) => {
    
		let deliveryVal = document.getElementById("delivery").value;
		let symbol = data.currency;
		let cost = parseFloat(data.totalCost);
		let discount = parseFloat(data.discount);
    let tax = parseFloat(data.tax);
		let delivery = parseFloat(deliveryVal.replace(/[£$€]/g, ""));
		let discountWrap = document.getElementById("discountWrap");
    let costIncShipping = parseFloat(cost + delivery);
		
    errOut.innerHTML = data.message;

		document.getElementById("VAT").value = symbol + tax.toFixed(2)
		document.getElementById("total").value = symbol + costIncShipping.toFixed(2)
		document.getElementById("discount").value = "- " + symbol + discount.toFixed(2)

		if(discount > 0){
			discountWrap.style.display = "block";
			
			document.getElementById("code").value = "";
		} else {
			discountWrap.style.display = "none";
			document.getElementById("code").value = "";
		}

	}
}
let output,postcodeData;
class checkoutReskin {

    constructor(section) {

        this.section = section;
        this.manualAddressToggle = section.querySelector('.js-toggle-group');
        this.manualAddressToggleBilling = section.querySelector('.js-toggle-group-billing');
        this.billingSameAsDeliveryToggle = section.querySelector('#billingSameAsDelivery');
        this.addressGroup = section.querySelector('.js-delivery-address');
        this.addressBillingGroup = section.querySelector('.js-billing-address');
        this.postcodeLookupBtn = section.querySelector('.js-postcode-lookup');
        this.submitButton = section.querySelector('.js-checkout-submit');
        this.signInButton = section.querySelector('.black-btn');
        this.addressBillingGroupManual = section.querySelector('.postcode-toggled-billing');
        this.postcodeLookupBtnBilling = section.querySelector('.js-postcode-lookup-billing');
        this.init();
    }

    init() {
     
        this.signIn(); 
        this.toggleManualAddress();
        this.toggleBillingAddress();
        this.toggleManualBillingAddress();
        this.toggleStateField();
        this.postcodeLookup();
        this.postcodeLookupBilling();
        this.validator();
        this.submitOrder();

    }

    signIn(e){
      // ***********
      // ***STEPS***
      // ***********
      //  MAKE A REQUEST TO CHECK SIGN IN DETAILS ARE CORRECT
      // IF NOT, NOTIFY
      // IF CORRECT, FILL IN THE FORMS WITH THE RELEVANT INFO
        this.signInButton.addEventListener('click', (e) => {
          e.preventDefault();
          let p = document.getElementById("password"),
              u = document.getElementById("email");
             
          $.ajax({
            type: "POST",
            cache: false,
            async: false,
            url: '/js/xhr/validateDetails.php',
            data: {
              user: u.value,
              pass: p.value
            },
            success:function(data) {
              // console.log(data)
              if(data === "False"){
                output = false
              } else {
                output = JSON.parse(data)

                // Disable the sign-in form, create account etc.
                let nonAuth = document.querySelectorAll('.js-non-auth-container');

                if (nonAuth.length > 0) {

                    nonAuth.forEach(elem => {

                    elem.classList.add('is-hidden');
  
                    setTimeout((e) => {
                      elem.classList.add('is-visually-hidden');
                    }, 500);
                  });
                }

                /*let tariff = document.querySelector('[name=deliveryMethod]');
                let country = document.querySelector('[name=deliveryCountry]');

                if (tariff && country) {
                  Shipping.updateTariff(tariff.value, country.value);
              }*/
              }
          },
            error: function(err){
              console.log("Error: " + err);
          }
        }).then( this.fillData(output));
       
    });
   
  }

    fillData(data){
        // console.log(data)
        if(!data){
          let errmsg = document.getElementById("errorMSG");
          errmsg.innerHTML = "Username or Password is incorrect"
          setTimeout(function(){errmsg.innerHTML = ""; }, 3000);
        } else{
          //alert('hey'); 
          this.signInButton.disabled = true;
          this.signInButton.classList.add("disabledBlackBtn") ;
          document.getElementById("title").value = data.user.title;
          document.getElementById("firstName").value = data.user.first;
          document.getElementById("surname").value = data.user.surname;

          document.getElementById("emailAddress").value = data.user.email ;

          document.getElementById("createAccount").checked = false ;
          document.getElementById("OptinMailing").checked = false ;
          document.querySelectorAll(".alternativeAddress ")[0].value = data.delivery.postcode;
          this.addressGroup.classList.remove('is-hidden');
          document.getElementById("deliveryTitle").value = data.user.title;
          document.getElementById("deliveryFirstName").value = data.user.first;
          document.getElementById("deliverySurname").value = data.user.surname;
          document.getElementById("deliveryAddress1").value = data.delivery.addLine1;
          document.getElementById("deliveryAddress2").value = data.delivery.addLine2;
          document.getElementById("deliveryCity").value = data.delivery.city;
          document.getElementById("deliveryPostcode").value = data.delivery.postcode;
          document.getElementById("deliveryCountry").value = data.delivery.country ;

          this.addressBillingGroup.classList.remove('is-hidden');
          document.getElementById("billingSameAsDelivery").checked = false ;
          document.getElementById("billingTitle").value = data.billing.title;
          document.getElementById("billingFirstName").value = data.billing.first;
          document.getElementById("billingSurname").value = data.billing.surname;
          document.getElementById("billingAddress1").value = data.billing.addLine1;
          document.getElementById("billingAddress2").value = data.billing.addLine2;
          document.getElementById("billingCity").value = data.billing.city;
          document.getElementById("billingPostcode").value = data.billing.postcode;
          document.getElementById("billingCountry").value = data.billing.country ;

          /**
           * Fire off a custom event so we can watch it.
           */
          const event = new Event('deliveryCountryChanged');
          document.getElementById('deliveryMethod').dispatchEvent(event);
        }
    }

    toggleManualAddress() {

        this.manualAddressToggle.addEventListener('click', (e) => {

            if (this.addressGroup.classList.contains('is-hidden')) {

                this.addressGroup.classList.remove('is-hidden');

            } else {

                this.addressGroup.classList.add('is-hidden');

            }

        });


    }

    toggleManualBillingAddress() {

      this.manualAddressToggleBilling.addEventListener('click', (e) => {

          if (this.addressBillingGroupManual.classList.contains('is-hidden')) {

            this.addressBillingGroupManual.classList.remove('is-hidden');

          } else {

            this.addressBillingGroupManual.classList.add('is-hidden');

          }

      });


  }

    toggleBillingAddress() {
        this.billingSameAsDeliveryToggle.addEventListener('change', (e) => {

            if (this.addressBillingGroup.classList.contains('is-hidden')) {

                this.addressBillingGroup.classList.remove('is-hidden');

            } else {

                this.addressBillingGroup.classList.add('is-hidden');

            }


        });


    }

    toggleStateField() {
      let deliveryCountryField = document.getElementById("deliveryCountry");
      let billingCountryField = document.getElementById("billingCountry");
      let deliveryStateField = document.getElementById("deliveryState").parentNode;
      let billingStateField = document.getElementById("billingState").parentNode;

      deliveryCountryField.addEventListener('change', (e) => {
          if (deliveryCountryField.value === '235') {
            deliveryStateField.classList.remove('hidden');
          } else {
            deliveryStateField.classList.add('hidden');
          }
      });

      billingCountryField.addEventListener('change', (e) => {
          if (billingCountryField.value === '235') {
            billingStateField.classList.remove('hidden');
          } else {
            billingStateField.classList.add('hidden');
          }
      });
    }

    postcodeLookup() {

        this.postcodeLookupBtn.addEventListener('click', (e) => {

            let error = false;
            let errorMsg = '';
            let postcode = document.querySelector('.js-postcode');
            // console.log(postcode.value)
            if (postcode.value.length > 0) {

              let formData = new FormData();

              formData.append('postcode', postcode.value);
  
              let request = {
                  method: 'POST',
                  url: '/js/xhr/postcodeLookup.php',
                  data: formData
              };

              axios(request).then((response) => {

                this.removeError(postcode);

                if (response.status == 200) {
                  this.appendPostcodeAddresses(response.data);

                  //ok so lets recalculate vat based on the country that the postcode lookup has selected.
                  setTimeout(function(){

                    $.post("/js/xhr/calculateTax.php", {
                      country: $('#deliveryCountry').val(),
                      shippingValue: $('#delivery').val()
                    }, 
                    function(response){
                      var jsonData = JSON.parse(response); 
                
                      //ok some territories dont require vat removal such as the uk
                
                      if(jsonData.summary.rateUsed!='GBR'){
                        
                        $('.vatRemoval-total').slideDown(300);
                        $('#vatRemoval').val('-'+jsonData.summary.formattedVatRemoval);
                        if(formattedDutyTotal!='£0.00'){
                          $('.duty-total').slideDown(300);
                        }else{
                          $('.duty-total').slideUp(300);
                        }
                        $('.cart-vat').slideUp(300);
                        $('#duty').val(jsonData.summary.formattedDutyTotal);
                        $('.localSalesTax-total').slideDown(300);
                        $('#localSalesTax').val(jsonData.summary.formattedVatTotal);
                        //$('#subtotal').val(jsonData.summary.formattedBasketTotal);
                        $('#total').val(jsonData.summary.formattedBasketTotalIncShipping);
                        $('.subTotal-total').removeClass('first');
                      }else{
                        
                        $('.cart-vat').slideDown(300);
                        $('.vatRemoval-total').slideUp(300);
                        $('#vatRemoval').val(0);
                        $('.duty-total').slideUp(300);
                        $('#duty').val(0);
                        $('.localSalesTax-total').slideUp(300);
                        $('#localSalesTax').val(0);
                        $('#subtotal').val(jsonData.summary.formattedBasketTotal);
                        $('#total').val(jsonData.summary.formattedBasketTotalIncShipping);
                        
                        $('.subTotal-total').addClass('first');  
                      }
                    });

                  },2000);
                  
                }
              }).catch((failure) => {
                this.addError(postcode, failure.response.data);
                setTimeout(() => {
                  this.removeError(postcode);
                }, 2500);
              });

            } else {
              error = false;
              errorMsg = '';

              if (error === false) {
                if (postcode.value.length > 0) {
                  if (postcode.classList.contains('has-error')) {
                    postcode.classList.remove('has-error');
                  }
                } else {
                  error = true;
                  postcode.classList.add('has-error');
                  errorMsg = 'Please fill this first';
                  setTimeout(() => {
                    this.removeError(postcode);
                  }, 2500);
                }
              }

              let parent = postcode.parentNode;
              let existingErrorDiv = parent.querySelector('.postcode-error');

              if (existingErrorDiv) {
                errorDiv.innerHTML = errorMsg;
              } else {
                let errorDiv = document.createElement("div");
                parent.appendChild(errorDiv);
                errorDiv.classList.add('postcode-error');
                errorDiv.classList.add('error');
                errorDiv.innerHTML = errorMsg;
              }
            }

            postcode.addEventListener('change', function(e) {
              error = false;
              errorMsg = '';

              if (error === false) {
                if (postcode.value.length > 0) {
                  if (postcode.classList.contains('has-error')) {
                    postcode.classList.remove('has-error');
                  }
                } else {
                  error = true;
                  postcode.classList.add('has-error');
                  errorMsg = 'Please fill this first';
                  setTimeout(() => {
                    this.removeError(postcode);
                  }, 2500);
                }
              }

              let parent = postcode.parentNode;
              let existingErrorDiv = parent.querySelector('.postcode-error');

              if (error === true) {
                if (existingErrorDiv) {
                  existingErrorDiv.innerHTML = errorMsg;
                } else {
                  let errorDiv = document.createElement("div");
                  parent.appendChild(errorDiv);
                  errorDiv.classList.add('postcode-error');
                  errorDiv.classList.add('error');
                  errorDiv.innerHTML = errorMsg;
                }
              } else {
                if (existingErrorDiv) {
                  existingErrorDiv.innerHTML = '';
                }
              }

            });
        });

    }

    postcodeLookupBilling() {

      this.postcodeLookupBtnBilling.addEventListener('click', (e) => {

        
          let error = false;
          let errorMsg = '';
          let postcode = document.querySelector('.js-postcode-billing');
          // console.log(postcode.value)
          if (postcode.value.length > 0) {

            let formData = new FormData();

            formData.append('postcode', postcode.value);

            let request = {
                method: 'POST',
                url: '/js/xhr/postcodeLookup.php',
                data: formData
            };

            axios(request).then((response) => {

              this.removeError(postcode);

              if (response.status == 200) {
                this.appendPostcodeAddressesBilling(response.data);
              }
            }).catch((failure) => {
              this.addError(postcode, failure.response.data);
            });

          } else {
            error = false;
            errorMsg = '';

            if (error === false) {
              if (postcode.value.length > 0) {
                if (postcode.classList.contains('has-error')) {
                  postcode.classList.remove('has-error');
                }
              } else {
                error = true;
                postcode.classList.add('has-error');
                errorMsg = 'Please fill this first';
                setTimeout(() => {
                  this.removeError(postcode);
                }, 2500);
              }
            }

            let parent = postcode.parentNode;
            let existingErrorDiv = parent.querySelector('.postcode-error');

            if (existingErrorDiv) {
              errorDiv.innerHTML = errorMsg;
            } else {
              let errorDiv = document.createElement("div");
              parent.appendChild(errorDiv);
              errorDiv.classList.add('postcode-error');
              errorDiv.classList.add('error');
              errorDiv.innerHTML = errorMsg;
            }
          }

          postcode.addEventListener('change', function(e) {
            error = false;
            errorMsg = '';

            if (error === false) {
              if (postcode.value.length > 0) {
                if (postcode.classList.contains('has-error')) {
                  postcode.classList.remove('has-error');
                }
              } else {
                error = true;
                postcode.classList.add('has-error');
                errorMsg = 'Please fill this first';
                setTimeout(() => {
                  this.removeError(postcode);
                }, 2500);
              }
            }

            let parent = postcode.parentNode;
            let existingErrorDiv = parent.querySelector('.postcode-error');

            if (error === true) {
              if (existingErrorDiv) {
                existingErrorDiv.innerHTML = errorMsg;
              } else {
                let errorDiv = document.createElement("div");
                parent.appendChild(errorDiv);
                errorDiv.classList.add('postcode-error');
                errorDiv.classList.add('error');
                errorDiv.innerHTML = errorMsg;
              }
            } else {
              if (existingErrorDiv) {
                existingErrorDiv.innerHTML = '';
              }
            }

          });
      });

  }
    addError(element, message) {

      let parent = element.parentNode;
      let errorDiv = document.createElement("div");
      let errorDivSelector = element.name + '-error';
      let existingErrorDiv = parent.querySelector('.' + errorDivSelector);


      if (existingErrorDiv) {
        existingErrorDiv.innerHTML = message;
      } else {
        errorDiv.innerHTML = message;
        parent.appendChild(errorDiv);
      }

      element.classList.add('has-error');
      errorDiv.classList.add(errorDivSelector);
      errorDiv.classList.add('error');
    }

    removeError(element) {

      let parent = element.parentNode;
      let errorDivSelector = element.name + '-error';
      let existingErrorDiv = parent.querySelector('.' + errorDivSelector);

      if (existingErrorDiv) {
        existingErrorDiv.innerHTML = '';
      }

      element.classList.remove('has-error');
    }

    appendPostcodeAddresses(data) {
        // console.log(data)
        
        let addressBox = document.querySelector('.postcode-lookup-result');
        let selectBox = addressBox.querySelector('.chosen-postcode'); 

        let i = 0;
          
        selectBox.innerHTML = "";

        data.addresses.forEach(address => {
            selectBox.innerHTML += '<option value="'+i+'" class="postcode-address">'+this.formatAddress(address.formatted_address)+'</option>';
            i++;
        });

        if (addressBox.classList.contains('hidden')) {
            addressBox.classList.remove('hidden');
        }

        this.insertAddressOnSelect(data);
    }

    appendPostcodeAddressesBilling(data) {
      // console.log(data)
      let addressBox = document.querySelector('.postcode-lookup-result-billing');
      let selectBox = addressBox.querySelector('.chosen-postcode-billing');

      let i = 0;
        
      selectBox.innerHTML = "";

      data.addresses.forEach(address => {
          selectBox.innerHTML += '<option value="'+i+'" class="postcode-address">'+this.formatAddress(address.formatted_address)+'</option>';
          i++;
      });

      if (addressBox.classList.contains('hidden')) {
          addressBox.classList.remove('hidden');
      }

      this.insertAddressOnSelectBilling(data);
  }

    insertAddressOnSelect(data) {
      let addressBox = document.querySelector('.postcode-lookup-result');
      let selectBox = addressBox.querySelector('.chosen-postcode');
      let section = document.querySelector('.checkout-section');
      let addressGroup = section.querySelector('.js-delivery-address');

      selectBox.addEventListener('change', (e) => {
        
          $('#deliveryFirstName').val($('#firstName').val());
          $('#deliverySurname').val($('#surname').val());

          if (addressGroup.classList.contains('is-hidden')) {
              addressGroup.classList.remove('is-hidden');
          }

          let selectedAddress = data.addresses[selectBox.value];
          
          document.getElementById("deliveryAddress1").value = selectedAddress.line_1;
          document.getElementById("deliveryAddress2").value = selectedAddress.line_2;
          document.getElementById("deliveryCity").value = selectedAddress.town_or_city;
          document.getElementById("deliveryPostcode").value = data.postcode;
          document.getElementById("deliveryCountry").value = '234';
      });
    }

    insertAddressOnSelectBilling(data) {
      let addressBox = document.querySelector('.postcode-lookup-result-billing');
      let selectBox = addressBox.querySelector('.chosen-postcode-billing');
      let section = document.querySelector('.checkout-section')
      let addressGroup = section.querySelector('.postcode-toggled-billing');

      selectBox.addEventListener('change', (e) => {
        
          $('#billingFirstName').val($('#firstName').val());
          $('#billingSurname').val($('#surname').val());

          if (addressGroup.classList.contains('is-hidden')) {
              addressGroup.classList.remove('is-hidden');
          }

          let selectedAddress = data.addresses[selectBox.value];

          document.getElementById("billingAddress1").value = selectedAddress.line_1;
          document.getElementById("billingAddress2").value = selectedAddress.line_2;
          document.getElementById("billingCity").value = selectedAddress.town_or_city;
          document.getElementById("billingPostcode").value = data.postcode;
          document.getElementById("billingCountry").value = '234';

      });
    }

    formatAddress(address) {

        let formatted = '';

        const length = address.length;

        address.forEach(addressPart => {

            let index = address.indexOf(addressPart);

            if (index == length) {

                formatted += '' + addressPart + '';

            } else if (addressPart.length == 0) {

            } else {
                formatted += '' + addressPart + ', ';
            }
        });

        return formatted.replace(/,\s*$/, "");

    }

    validator() {
      
      var validate = document.querySelectorAll('.js-validate');
      for (var i = 0; i < validate.length; i++) {
        let self = validate[i];
        let error = false;
        let errorMsg = '';
        let errorDivSelector = 'error-' + i;
        self.addEventListener('change', function(e) {
          error = false;
          errorMsg = '';

          if (error === false) {
            if (self.classList.contains('is-required')) {
              if (self.value.length > 0 && self.value != 0) {
                if (self.classList.contains('has-error')) {
                  self.classList.remove('has-error');
                }
              } else {
                error = true;
                self.classList.add('has-error');
                errorMsg = 'This field is required';
              }
            }
          }

          if (error === false) {
            if (document.querySelector('#billingSameAsDelivery').checked === false) {
              if (self.classList.contains('is-required--billing')) {
                if (self.value.length > 0 && self.value != 0) {
                  if (self.classList.contains('has-error')) {
                    self.classList.remove('has-error');
                  }
                } else {
                  error = true;
                  self.classList.add('has-error');
                  errorMsg = 'This field is required';
                }
              }
            }
          }

          if (error === false) {
            if (document.getElementById("deliveryCountry").value === 235) {
              if (self.classList.contains('is-required--country-state')) {
                if (self.value.length > 0 && self.value != 0) {
                  if (self.classList.contains('has-error')) {
                    self.classList.remove('has-error');
                  }
                } else {
                  error = true;
                  self.classList.add('has-error');
                  errorMsg = 'This field is required';
                }
              }
            }
          }

          if (error === false) {
            if (document.getElementById("billingCountry").value === 235) {
              if (self.classList.contains('is-required--billing-state')) {
                if (self.value.length > 0 && self.value != 0) {
                  if (self.classList.contains('has-error')) {
                    self.classList.remove('has-error');
                  }
                } else {
                  error = true;
                  self.classList.add('has-error');
                  errorMsg = 'This field is required';
                }
              }
            }
          }

          if (error === false) {
            if (self.classList.contains('is-numeric')) {
              if (/^\d+$/.test(self.value)) {
                if (self.classList.contains('has-error')) {
                  self.classList.remove('has-error');
                }
              } else {
                error = true;
                self.classList.add('has-error');
                errorMsg = 'Number you have entered is invalid';
              }
            }
          }

          if (error === false) {
            if (self.classList.contains('is-email')) {
              if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(self.value)) {
                if (self.classList.contains('has-error')) {
                  self.classList.remove('has-error');
                }
              } else {
                error = true;
                self.classList.add('has-error');
                errorMsg = 'Email you have entered is invalid';
              }
            }
          }

          let parent = self.parentNode;
          let existingErrorDiv = parent.querySelector('.' + errorDivSelector);

          if (error === true) {
            if (existingErrorDiv) {
              existingErrorDiv.innerHTML = errorMsg;
            } else {
              let errorDiv = document.createElement("div");
              parent.appendChild(errorDiv);
              errorDiv.classList.add(errorDivSelector);
              errorDiv.classList.add('error');
              errorDiv.innerHTML = errorMsg;
            }
          } else {
            if (existingErrorDiv) {
              existingErrorDiv.innerHTML = '';
            }
          }

        });
      };
    }

    validate() {
      
      var validate = document.querySelectorAll('.js-validate');
      for (var i = 0; i < validate.length; i++) {
        let self = validate[i];

        let error = false;
        let errorMsg = '';
        let errorDivSelector = 'error-' + i;
          error = false;
          errorMsg = '';

          if (error === false) {
            if (self.classList.contains('is-required')) {
              if (self.value.length > 0 && self.value != 0) {
                if (self.classList.contains('has-error')) {
                  self.classList.remove('has-error');
                }
              } else {
                error = true;
                self.classList.add('has-error');
                errorMsg = 'This field is required';
              }
            }
          }

          if (error === false) {
            if (document.querySelector('#billingSameAsDelivery').checked === false) {
              if (self.classList.contains('is-required--billing')) {
                if (self.value.length > 0 && self.value != 0) {
                  if (self.classList.contains('has-error')) {
                    self.classList.remove('has-error');
                  }
                } else {
                  error = true;
                  self.classList.add('has-error');
                  errorMsg = 'This field is required';
                }
              }
            }
          }

          if (error === false) {
            if (document.getElementById("deliveryCountry").value == 235) {
              if (self.classList.contains('is-required--delivery-state')) {
                if (self.value.length > 0 && self.value != 0) {
                  if (self.classList.contains('has-error')) {
                    self.classList.remove('has-error');
                  }
                } else {
                  
                  error = true;
                  self.classList.add('has-error');
                  errorMsg = 'This field is required';
                }
              }
            }
          }

          if (error === false) {
            if (document.getElementById("billingCountry").value == 235) {

              
              if (self.classList.contains('is-required--billing-state')) {
                if (self.value.length > 0 && self.value != 0) {
                  if (self.classList.contains('has-error')) {
                    self.classList.remove('has-error');
                  }
                } else {
                  
                  error = true;
                  self.classList.add('has-error');
                  errorMsg = 'This field is required';
                }
              }
            }
          }

          if (error === false) {
            if (self.classList.contains('is-numeric')) {
              if (/^\d+$/.test(self.value)) {
                if (self.classList.contains('has-error')) {
                  self.classList.remove('has-error');
                }
              } else {
                error = true;
                self.classList.add('has-error');
                errorMsg = 'Number you have entered is invalid';
              }
            }
          }

          if (error === false) {
            // console.log("Checking email")
            if (self.classList.contains('is-email')) {
              
              if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(self.value)) {
                if (self.classList.contains('has-error')) {
                  self.classList.remove('has-error');
                }
              } else {
                error = true;
                self.classList.add('has-error');
                errorMsg = 'Email you have entered is invalid';
              }
            }
          }

          let parent = self.parentNode;
          let existingErrorDiv = parent.querySelector('.' + errorDivSelector);

          if (error === true) {

            if (parent.offsetParent === null) {

              let formGroup = parent.parentNode;

              if (formGroup) {
                formGroup.classList.remove('is-hidden');
              }
            }

            if (existingErrorDiv) {
              existingErrorDiv.innerHTML = errorMsg;
            } else {
              let errorDiv = document.createElement("div");
              parent.appendChild(errorDiv);
              errorDiv.classList.add(errorDivSelector);
              errorDiv.classList.add('error');
              errorDiv.innerHTML = errorMsg;
            }
          } else {
            if (existingErrorDiv) {
              existingErrorDiv.innerHTML = '';
            }
          }
      };
    }

    checkForErrors() {
      var errors = document.querySelectorAll('.has-error');
      if(errors.length > 0){
            var rect = errors[0].getBoundingClientRect();
            window.scrollTo({top: rect.top, behavior: 'smooth'});
      }
      let error = false;

      for (var i = 0; i < errors.length; i++) {
        error = true;
      }
     
      return error;
    }

    submitOrder() {

      this.submitButton.addEventListener('click', (e) => {

        e.preventDefault();
        
        this.validate();

        let error = this.checkForErrors();

        if (this.submitButton.getAttribute('disabled') === true) {
          return;
        }

        if (error === false) {

          var sameAsDelivery;
          if($("#billingSameAsDelivery").prop("checked") == true){
            sameAsDelivery = 1;
          } else {
            sameAsDelivery = 0;
          }

          var giftWrapped;
          if($("#giftWrapped").prop("checked") == true){
            giftWrapped = 1;
          } else {
            giftWrapped = 0;
          }
          let agreeTermsCheckbox = document.getElementById("agreeTerms")
          let errorMsg = '';
          let errorDiv = document.createElement("div");
         
          if(agreeTermsCheckbox.checked == false){
            errorMsg = 'Please check the checkbox before continuing'
            agreeTermsCheckbox.parentNode.appendChild(errorDiv);
            errorDiv.classList.add('error');
            errorDiv.innerHTML = errorMsg;
            setTimeout(() => {
                errorDiv.remove();
            }, 4000);
          } else {
            let terms;
            if(agreeTermsCheckbox.checked == false){
              terms = 0;
            } else {
              terms = 1;
            }

            let createAccount = false;
            let createAccountElem = document.getElementById("createAccount");

            if (createAccountElem) {
              createAccount = createAccountElem.value;
            }


            let optinMailing = false;
            let optinMailingElem = document.getElementById("OptinMailing");

            if (optinMailingElem) {
              optinMailing = optinMailingElem.value;
            }

            this.submitButton.setAttribute("disabled", true);

            $.ajax({
      			  type: "POST",
      			  cache: false,
      			  url: "/js/xhr/submitAjaxOrder.php",
              data: {
                sameAsDelivery,
                title: document.getElementById("title").value,
                firstName: document.getElementById("firstName").value,
                surname: document.getElementById("surname").value,
                phoneNumber: document.getElementById("phoneNumber").value,
                email: document.getElementById("emailAddress").value,
                createAccount: createAccount,
                OptinMailing: optinMailing,
                deliveryTitle: document.getElementById("deliveryTitle").value,
                deliveryFirstName: document.getElementById("deliveryFirstName").value,
                deliverySurname: document.getElementById("deliverySurname").value,
                deliveryAddressLine1: document.getElementById("deliveryAddress1").value,
                deliveryAddressLine2: document.getElementById("deliveryAddress2").value,
                deliveryCity: document.getElementById("deliveryCity").value,
                deliveryPostcode: document.getElementById("deliveryPostcode").value,
                deliveryState: document.getElementById("deliveryState").value,
                deliveryCountry: document.getElementById("deliveryCountry").value,
                deliveryMethod: document.getElementById("deliveryMethod").value,
                deliveryNotes: document.getElementById("deliveryNotes").value,
                giftWrapped,
                giftMessage: document.getElementById("giftOrderMessage").value,
                billingTitle: document.getElementById("billingTitle").value,
                billingFirstName: document.getElementById("billingFirstName").value,
                billingSurname: document.getElementById("billingSurname").value,
                billingAddressLine1: document.getElementById("billingAddress1").value,
                billingAddressLine2: document.getElementById("billingAddress2").value,
                billingCity: document.getElementById("billingCity").value,
                billingPostcode: document.getElementById("billingPostcode").value,
                billingState: document.getElementById("billingState").value,
                billingCountry: document.getElementById("billingCountry").value,
                shipping: document.getElementById("deliveryMethod").value,
                terms: terms
              },
              success: function(response) {
                // console.log(response);
        				try {
                  response = $.parseJSON(response);

                  if(response.error) {

                    setTimeout(document.querySelector('.js-checkout-submit').removeAttribute('disabled'), 1000);

        						if(response.fields !== undefined) {

        							var errorListItems = '',
        								hasError = false,
        								iError = 0;

        							for(iError = 0; iError < response.fields.length; iError++) {

        								var error = response.fields[iError];
        								if(error.status === false) {
        									hasError = true;
        								}
        							}

        							if(hasError) {
                        // console.log("HAS ERR response.fields")
                        // console.log(response.fields);
                        // $('.js-basket-error-display').html('An error occurred. Please contact us if you require further assistance.');
                        for(const errStatus of response.fields){
                          if(errStatus.status == false){
                            $('.js-basket-error-display').html(errStatus.message);
                            $([document.documentElement, document.body]).animate({
                                scrollTop:  $('.js-basket-error-display').offset().top
                            }, 500); 
                          }
                        }
                        // $('.js-basket-error-display').html(error.status);

        							}

        						} else {
                      $('.js-basket-error-display').html('An error occurred. Please contact us if you require further assistance.');
                      $([document.documentElement, document.body]).animate({
                          scrollTop:  $('.js-basket-error-display').offset().top
                      }, 500); 
        						}
                  } else {
                    // Redirect the user to SagePay
                    setTimeout(document.querySelector('.js-checkout-submit').removeAttribute('disabled'), 1000);
                    window.location.href = response.url;
                  }
        				}
        				catch (e) {
                  console.log("Err in catch")
                  console.log(e);
        					$('.js-basket-error-display').html('An error occurred. Please contact us if you require further assistance.');
                  $([document.documentElement, document.body]).animate({
                      scrollTop:  $('.js-basket-error-display').offset().top
                  }, 500); 
                  setTimeout(document.querySelector('.js-checkout-submit').removeAttribute('disabled'), 1000);
        				}



        			},
        			error: function() {
        				alert('Sorry, there was an error processing your request.');
                setTimeout(document.querySelector('.js-checkout-submit').removeAttribute('disabled'), 1000);
        			}
        		});
            } 
          }
      });
    }

    makeRequest(request, callback) {

        axios(request).then((response) => {
            if (response.status == 200 || response.status == 201) {
                callback(response);
            }
        }).catch((error) => {
            console.log(error);
        });
    }


}

export default checkoutReskin;

document.addEventListener("DOMContentLoaded", function () {

    const checkoutSection = document.querySelector('.checkout-section');

    if (checkoutSection) {

        new checkoutReskin(checkoutSection);

    }



});
