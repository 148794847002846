import Validation from './validation';
import Eskimo from './eskimo';

var Form = function() {
    'use strict';

	var options = {
        element: $('form:not(.no-validation)'),
        countries: $('form select.country')
    };

    function canInit() {
        if (options.element.length) {
            return true;
        }
        return false;
    }

	function init(params) {
        options = $.extend({}, options, params);

        if (canInit()) {
            initCountryChange();
            submitForm();

            //Honeypot:
            options.element.find('[name=email-secondary]').hide();
            
            console.log('[Form] initialized');
        }
    }

    function initCountryChange()
    {
        $.each($('form select.country'), function(i, country){
            hideUsStates($(country));
            $(country).off('change');
            $(country).change(function(){
                hideUsStates($(country));
            });
        });
    }

    function hideUsStates(country)
    {
        let parent = country.parent().parent();
        if (country.val() == 235) {
            hideStateField(parent.find('.no-usa-state'));
            showStateField(parent.find('.usa-state'));
        } else {
            showStateField(parent.find('.no-usa-state'));
            hideStateField(parent.find('.usa-state'));
        }
    }

    function hideStateField(fieldset)
    {
        fieldset.find('input, select').prop('disabled', true);
        fieldset.hide();
    }

    function showStateField(fieldset)
    {
        fieldset.find('input, select').prop('disabled', false);
        fieldset.show();
        fieldset.find('select').dropkick('refresh');
    }

    function submitForm(){
        options.element.on('submit', function (e) {
            
            var form = $(this);
            
            if(!Validation.validateForm(form)){
                e.preventDefault();
                
                Eskimo.showError(form, "Please complete all required fields.");
                $("html, body").animate({ scrollTop: 0 }, "slow");

            } else {
                form.trigger('validated', {e:e});
            }

            // var thisXhr,
            //     thisElement;

            // if(event.currentTarget.classList.contains('form-application')){
            //     thisXhr = "/xhr/application.php";
            //     thisElement = options.application;
            // } else if(event.currentTarget.classList.contains('form-my-account')) {
            //     thisXhr = "/xhr/trade-my-account.php";
            //     thisElement = options.account;
            // }

            // else {
            //     thisXhr = "/xhr/contact-band.php";
            //     thisElement = options.application;
            // }

            // if (!Validation.validateForm(form)) {
            //     Eskimo.showError(form, "Please check your form for errors and try again.");
            //     return false;
            // }

            // form.trigger('validated');
        
            // // reCaptcha
            // if(typeof grecaptcha != 'undefined'){
            //     e.preventDefault();
            //     grecaptcha.execute(GCAPTCHA_KEY)
            //     .then(function(token) {
            //         form.trigger('captcha-success');
            //         console.log('captcha success');

            //     }, function(reason) {
            //         form.trigger('captcha-failed')
            //     });
            // }
        }); 
    }

    // function initReCaptcha(thisForm, file){ 
    //     var formElement = thisForm;

    //     grecaptcha.ready(function() {
    //         try{
    //             grecaptcha.execute(GCAPTCHA_KEY, {action: 'trade'})
    //                 .then(function(token) {
                        
    //                     console.log('success!');
    //                     //submitForm(formElement, file);

    //                 }, function(reason) { 
    //                 });

    //         } catch(e) {
    //             console.log(e.message);
    //         }
    //     });
    // }

    return {
    	init: init,
        initCountryChange: initCountryChange
    };

}();

export default Form;