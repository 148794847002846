

class accordion {

    constructor(acc) {

        this.accordion = acc;
        this.init();

    }

    init() {

        this.accordion.addEventListener('click', (e) => {

            this.toggle();

        });


    }

    toggle() {

        let accordionContent = this.accordion.querySelector('.accordion__content');
        let otherAccordions = document.querySelectorAll('.accordion__content');

        if(otherAccordions) {

            otherAccordions.forEach(accordion => {

                if(accordion != accordionContent) {

                    if(accordion.classList.contains('active')) {

                        accordion.classList.remove('active');

                        let parent = accordion.parentElement;

                        if(parent.classList.contains('open')) {

                            parent.classList.remove('open');

                        }

                    }

                }

            });

        }

        if(accordionContent) {

            if(accordionContent.classList.contains('active')) {

                accordionContent.classList.remove('active');

            } else {

                accordionContent.classList.add('active');

            }
            
        }

        if(this.accordion.classList.contains('open')) {

            this.accordion.classList.remove('open');

        } else {

            this.accordion.classList.add('open');

        }


    }

}

export default accordion;

document.addEventListener("DOMContentLoaded", function () {

    const accordions = document.querySelectorAll('.accordion');

    if(accordions) {

        accordions.forEach(el => {

            new accordion(el);

        });

    }

});