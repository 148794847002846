/**
 * Addressbook
 * Author: Maiko McElroy
 */
import Validation from './validation';
import form from './form';

var Addressbook = (function() {
    'use strict';

	var options = {
        element: $('.js-address'),
        edit: $('.js-address.edit'),
        delete: $('.js-address.delete'),
        add: $('.js-address.add'),
        primary: $('.js-address.primary')
    };

    function canInit() {
        if (options.element.length) {
            return true;
        }
        return false;
    }

	function init(params) {
        options = $.extend({}, options, params);

        if (canInit()) {

            initEdit();
            initAdd();
            initPrimary();
            initDelete();
            
            console.log('[Addressbook] initialized');
        }
    }

    function initEdit(){

        options.edit.each(function(i, elem){

            let id = $(elem).data('id');

            $(elem).magnificPopup({
                type: 'ajax',
                // preloader: false,
                modal: true,
                ajax:{
                    settings: {
                        url: '/js/xhr/trade/addressForm.php',
                        data: {id:id}
                    }
                },
                callbacks: {
                    ajaxContentAdded: function(){
                        bindAddressPopup(this.content);
                    }
                } 
            });
        });
    }

    function initAdd(){

        options.add.each(function(i, elem){

            $(elem).magnificPopup({
                type: 'ajax',
                // preloader: false,
                modal: true,
                ajax:{
                    settings: {
                        url: '/js/xhr/trade/addressForm.php'
                    }
                },
                callbacks: {
                    ajaxContentAdded: function(){
                        bindAddressPopup(this.content);
                    }
                } 
            });
        });
    }

    function bindAddressPopup(content)
    {
        form.initCountryChange();
        content.find('.form-buttons .close').click(function(e){
            e.preventDefault();
            $.magnificPopup.close();
        });
        content.find('.js-dropkick').dropkick({ mobile: true });
        content.find('form').submit(function(e){
            e.preventDefault();
            submitAddress($(this));
        });
    }

    function submitAddress(form)
    {
        if(!Validation.validateForm(form)){
            return;
        }
        form.find('input[type=submit]').prop('disabled', true);
        let data = form.serializeArray();
        $.ajax({
            url: '/js/xhr/trade/addressForm.php',
            method: 'POST',
            data: data
        }).done(function(data){
            if(data==''){
                location.reload();
            }
        });
    }

    function initPrimary(){
        options.primary.on('click', function(){
            let id = $(this).data('id');
            $.ajax({
                url: '/js/xhr/trade/addressPrimary.php',
                method: 'POST',
                data: {id:id}
            }).done(function(data){
                location.reload();
            });
        });
    }

    function initDelete(){
        $('#confirmation-popup button.close').click(function(){
            $.magnificPopup.close();
        });

        options.delete.each(function(i, elem){
            let id = $(this).data('id');
            $(elem).magnificPopup({
                type: 'inline',
                midClick: true,
                modal: true,
                callbacks: {
                    open: function(){
                        $(this.content).find('.button.pink').off('click').click(function(){
                            performDelete(id);
                        });
                    }
                } 
            });
        });
    }

    function performDelete(id)
    {
        $.ajax({
            url: '/js/xhr/trade/addressDelete.php',
            method: 'POST',
            data: {id:id}
        }).done(function(data){
            location.reload();
        });
    }

    return {
    	init: init
    };

})();

export default Addressbook;