let parents = document.querySelectorAll(".has-children");

if(parents){
    const handleTriggers = () => {
        let triggers = document.querySelectorAll(".has-children__toggle");
        let childNavsHTML =  document.querySelectorAll(".sidebar__nav-parent-menu"),
        childNavs = [...childNavsHTML];
        triggers.forEach((trigger) => {
            trigger.onclick = function(e) {
                e.preventDefault();
                var childMenu = childNavs.filter(menu => menu.dataset.upper == trigger.parentElement.dataset.sub);
                let symbolContainer = trigger.querySelector(".has-children__toggle-symbol");
                if(symbolContainer.innerHTML == "-"){
                    symbolContainer.innerHTML = "+"
                    childMenu[0].classList.add("childNotActive")
                } else {
                    symbolContainer.innerHTML = "-"   
                    childMenu[0].classList.remove("childNotActive");
                }
                
            }
        });
    }
    parents.forEach((parent) =>{
        const text = parent;
        text.insertAdjacentHTML('beforebegin', `<p class = "sidebar__nav-parent has-children" data-sub = "${text.dataset.sub}"><span class = "has-children__toggle">${text.children[0].innerHTML}<span class = "has-children__toggle-symbol">+</span></span></p>`);
        text.remove();
        handleTriggers();
    });
   
    


}