class shareButton {


    constructor(btn, inputEl) {


        this.btn = btn;
        this.input = inputEl;
        this.init();


    }

    init() {

        this.copyToClipboard();

    }

    copyToClipboard() {

        if (this.btn) {

            this.btn.addEventListener('click', (e) => {

                e.preventDefault();

                var copyLink = this.input;

                console.log(copyLink);

                copyLink.select();
                // copyLink.setSelectionRange(0, 99999); 

                document.execCommand('copy');

                this.userMessage();

            });

        }

    }

    userMessage() {

        const message = 'Link Copied';

        let shareMessage = document.querySelector('.share-text');

        shareMessage.innerHTML += message;


    }

}

export default shareButton;

document.addEventListener("DOMContentLoaded", function () {

    const btn = document.querySelector('.share-button');
    const linkToCopy = document.querySelector('.js-copy-input');


    if (btn && linkToCopy) {

        new shareButton(btn, linkToCopy);

    }

});