var ProgressBar = (function () {

    function init() {
        console.log('[Progress Bar] init');
        var progressBarElem = document.querySelector('.progress-bar')
        if (progressBarElem !== null) {
            progressBar(progressBarElem);
        }
    }

    function progressBar(element) {
        var trackActive = element.querySelector('.track__active');
        var pointsActive = element.querySelectorAll('.points__item[data-active]');
        var pointsActiveCount = pointsActive.length;
        var duration = 300;

        setTimeout(() => {
            trackActive.style.width = trackActive.dataset.percent + '%';

            pointsActive.forEach((point, i) => {
                setTimeout(() => {
                    point.classList.add('is-active');
                }, (duration / pointsActiveCount) * i);
            });
        }, 200);
    }

    return {
        init: init
    };

})();

export default ProgressBar;