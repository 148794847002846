/**
 * Featured Page
 * Author: Kyle Shearer
 */

let Featured = (function () {

    let options = {
        toggleStory: $('.js-toggle-story'),
        toggleStorySelector: '.js-toggle-story',
        featuredImageSelector: '.js-featured-image'
    };

    function canInit() {

        if (options.toggleStory.length) {
            return true;
        }

        return false;
    }

    function init(params) {
        options = $.extend({}, options, params);


        if (canInit()) {
            featuredStories();
        }
    }

    /**
     * Init the featured stories.
     */
    function featuredStories() {

        options.toggleStory.on('mouseenter', function(e){
            
            e.preventDefault();

            let id = $(this).attr('data-story');

            $( options.toggleStorySelector ).each(function(i, object) {
                if( $(object).attr('data-story') != id ) {
                    $(object).removeClass('is-active');
                } else {
                    $(object).addClass('is-active');
                }
            });

            $( options.featuredImageSelector ).each(function(i, object) {

                if( $(object).attr('data-story') != id ) {
                    $(object).removeClass('is-active');
                } else {
                    $(object).addClass('is-active');
                }
            });
        });
    }

    return {
        init: init
    };

})();

export default Featured;