

class navigation {

    constructor() {

        this.navIcon = document.querySelector('.toggle-menu-overlay');
        this.sidebar = document.querySelector('.sidebar__container');
        this.sidebarParentOptions = this.sidebar.querySelectorAll('.side-bar__nav-menu-item');
        this.mainBody = document.getElementById('division-main');
        this.searchIcon = this.sidebar.querySelector('.js-search-toggle');
        this.closeBtn = this.sidebar.querySelector('.js-close-menu');
        this.menuText = document.querySelector(".menu_text");
        this.menuBtn = document.querySelector(".menubtn");
        this.onLoad();
        this.navScroll();
        this.navToggle();
        this.navHover();
        this.bodyClick();
        this.toggleSearch();
        this.magicLine();
        this.closeMenu();

    }

    onLoad() {

        const header = document.getElementById('division-header');

        const bodyId = document.body.getAttribute('id');

        if (bodyId == 'mod-1') {
            header.classList.add('is-static');
        }

    }

    navScroll() {

        let scrollYInitial = (window.pageYOffset || document.documentElement.scrollTop);

        window.onscroll = () => {

            const scrollY = window.pageYOffset || document.documentElement.scrollTop;
            const body = document.querySelector('body');
            const header = document.getElementById('division-header');
            const headerDisable = (header.classList.contains('disable-hide') || header.classList.contains('is-static')) ? true : false;
            // const blackBackground = document.querySelector('.home-page__hair-journal');
            // const blogLanding = document.querySelector('.blog-landing');

            if (header.classList.contains('is-static')) {
                header.classList.remove('is-static');
            }

            if (headerDisable === false) {
                if (scrollY < scrollYInitial) {
                    header.classList.remove('is-hidden');
                    body.classList.remove('nav-hidden');
                } else {
                    header.classList.add('is-hidden');
                    body.classList.add('nav-hidden');
                }
                if (scrollY < header.clientHeight * 0.8) {
                    header.classList.remove('is-hidden');
                    body.classList.remove('nav-hidden');
                }
            }

            // if (scrollY === 0) {
            //     header.classList.add('is-static');
            // } 


            scrollYInitial = scrollY;
        };

    }

    navToggle() {

        if (this.navIcon) {

            this.navIcon.addEventListener('click', (e) => {

                this.showHide();

            });

        }



    }

    navHover() {

        if (this.sidebarParentOptions) {

            this.sidebarParentOptions.forEach(el => {

                el.addEventListener('click', (e) => {

                    e.preventDefault();

                    const dataName = el.getAttribute('data-name');

                    let childMenus = this.sidebar.querySelectorAll('.child-menu-container');

                    if (childMenus) {

                        childMenus.forEach(child => {

                            const parentAttr = child.getAttribute('data-parent');
                            
                            if (parentAttr == dataName) {
                                if(child.classList.contains('active') && dataName == "shop"){
                                    window.location.replace("/shop")
                                }
                                child.classList.add('active');

                            } else {

                                if (child.classList.contains('active')) {

                                    child.classList.remove('active');

                                }

                            }
                           
                        });

                    }


                    this.moveMagicLine(el);


                });

            });

        }

    }

    showHide() {

        if (document.body.classList.contains('nav-active')) {

            document.body.classList.remove('nav-active');

        } else {

            document.body.classList.add('nav-active');

        }

        if (this.navIcon.classList.contains('active')) {

            this.navIcon.classList.remove('active');
            this.menuText.innerHTML = "MENU"
        } else {
            this.menuText.innerHTML = "CLOSE"
            this.navIcon.classList.add('active');

        }

        if (this.sidebar) {

            if (this.sidebar.classList.contains('active')) {

                this.sidebar.classList.remove('active');
             

            } else {

                this.sidebar.classList.add('active');

            }

        }

    }

    bodyClick() {

        if (this.mainBody) {

            this.mainBody.addEventListener('click', (e) => {
                if(!e.target.classList.contains("black-btn")){
                if (document.body.classList.contains('nav-active')) {

                    document.body.classList.remove('nav-active');

                }
                }
                if (this.navIcon.classList.contains('active')) {

                    this.navIcon.classList.remove('active');

                }

                if (this.sidebar.classList.contains('active')) {

                    this.sidebar.classList.remove('active');

                }

                if(this.menuBtn.classList.contains("open")){
                    this.menuText.innerHTML = "MENU"
                    this.menuBtn.classList.remove("open");
                }

            });

        }

    }

    toggleSearch() {

        if (this.searchIcon) {

            this.searchIcon.addEventListener('click', (e) => {

                let childMenus = this.sidebar.querySelectorAll('.child-menu-container');

                if (childMenus) {

                    childMenus.forEach(child => {

                        if (child.classList.contains('js-search')) {

                            child.classList.add('active');

                            let elem = child.querySelector('[name=search]');

                            if (elem) {
                                elem.focus();
                            }

                        } else {

                            if (child.classList.contains('active')) {

                                child.classList.remove('active');

                            }

                        }

                    });


                }

            });

        }


    }

    magicLine() {

        const mainNav = document.querySelector('.sidebar__nav');
        const magicLine = document.createElement('div');
        magicLine.classList.add('js-magic-line');
        magicLine.classList.add('magic-line');

        if (mainNav) {
            mainNav.appendChild(magicLine);
            let currentSelection = mainNav.querySelector('.active');

            if (currentSelection) {

                let widthOfCurrent = currentSelection.offsetWidth;
                let offsetOfCurrent = currentSelection.offsetLeft;
                magicLine.style.width = widthOfCurrent + 'px';
                magicLine.style.left = offsetOfCurrent + 'px';

            }
        }

    }

    moveMagicLine(activeElement) {

        let magicLine = document.querySelector('.js-magic-line');

        if (magicLine) {

            const widthOfCurrent = activeElement.offsetWidth;
            const offsetOfCurrent = activeElement.offsetLeft;

            magicLine.style.width = widthOfCurrent + 'px';
            magicLine.style.left = offsetOfCurrent + 'px';


        }


    }

    closeMenu() {

        if (this.closeBtn) {

            this.closeBtn.addEventListener('click', (e) => {

                this.showHide();

            });

        }


    }



}

export default navigation;

document.addEventListener("DOMContentLoaded", function () {


    new navigation();


});



