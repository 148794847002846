

// class basketQuantity {

//     constructor() {

//         this.minus = document.querySelector('.productDetails .minus');
//         this.plus = document.querySelector('.productDetails .plus');
//         this.qty = document.getElementById('qty');
//         this.displayPrice = document.querySelector('.productPrice');
//         this.init();


//     }

//     init() {

//         this.minus.addEventListener('click', (e) => {

//             this.decrementQuantity();

//         });

//         this.plus.addEventListener('click', (e) => {

//             this.incrementQuantity();

//         });



//     }

//     decrementQuantity() {

//         let value = this.qty.value;
//         value--;

//         if (value >= 0) {
//             this.qty.value = value;
//             this.changePrice(this.qty.value);
//         }

//     }

//     incrementQuantity() {

//         let value = this.qty.value;
//         value++;

//         if (value >= 0) {
//             this.qty.value = value;
//             this.changePrice(this.qty.value);
//         }


//     }

//     changePrice(qty) {


//     }



// }

// export default basketQuantity;

// document.addEventListener("DOMContentLoaded", function () {

//     const productForm = document.querySelector('.productDetails');

//     if (productForm) {

//         new basketQuantity();

//     }



// });