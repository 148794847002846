function handleShareItem(shareItem) {
    var shareTrigger = shareItem.querySelector('.share-trigger');
    var shareClose = shareItem.querySelector('.share__list-close');

    if (shareTrigger !== null) {
        shareTrigger.addEventListener('click', function (event) {
            event.preventDefault();
            if(shareItem.classList.contains('is-active')){
                shareItem.classList.remove('is-active');
            }
            else{
                shareItem.classList.add('is-active');
            }
            
        });
    }

    if (shareClose !== null) {
        shareClose.addEventListener('click', function (event) {
            event.preventDefault();
            shareItem.classList.remove('is-active');
        });
    }
}

function initShareThis() {
    var shareItems = document.querySelectorAll('.js-share');

    for (let i = 0; i < shareItems.length; i += 1) {
        shareItems[i].addEventListener('click', function share(e) {
            e.preventDefault();
            return JSShare.go(this);
        });
    }

    var share = document.querySelectorAll('.share');

    if (share.length > 0) {
        share.forEach(function (shareItem) {
            handleShareItem(shareItem);
        })
    }

}

initShareThis();