let trigger = document.querySelectorAll(".category-display__nav--dropdown-trigger")[0];

if(trigger){
    console.log("[CATEGORY NAV] Init")

    trigger.addEventListener("click", ()=>{
        if(trigger.classList.contains("activeTrigger")){
            trigger.classList.remove("activeTrigger")
        } else {
            trigger.classList.add("activeTrigger")
        }
    });


}