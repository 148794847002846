// import Plyr from 'plyr';
document.addEventListener('DOMContentLoaded', function(){
        let plyrTrigger = document.getElementById("player");
    if(plyrTrigger){
        const player = new Plyr('#player', {
        captions: {active: true}, 
        autoplay:true,
        muted: true,
        loop: {active:true},
        controls: ['play', 'mute']
        });
    window.player = player;
    }
});
