import Addressbook from './components/addressbook';
import Checkbox from './components/checkbox';
import EskimoCheckout from './components/checkout';
import Copy from './components/copy';
import Dropkick from './components/dropkick';
import Eskimo from './components/eskimo';
import Fadedelay from './components/fadedelay';
import Form from './components/form';
import Matchheight from './components/matchheight';
import Order from './components/order';
import TradeAccount from './components/tradeAccount';
import Utils from './components/utils';
import Validation from './components/validation';
import WS from './components/ws';
import Menu from './components/menu';
import ProgressBar from './components/progressBar';
import TradeOrdersLink from './components/TradeOrdersLink';
import masonryComps from './components/masonrycomps';
import search from './components/search';
import currencyModal from './components/currencyModal';

//Old stuff, not sure what it does, not sure how...
import Legacy from './components/legacy'; 
import accordion from './components/accordion';
import basketQuantity from './components/basket';
import productSlider from './components/productSlider';
import navigation from './components/navigation';
import Featured from './components/Featured';
import subscribe from './components/subscribe';
import blog from './components/blog';
import stickyBasket from './components/stickyBasket';
import productImageZoom from './components/productImageZoom';
import shareButton from './components/shareButton';
import checkoutReskin from './components/checkoutReskin';
import projectGallery from './components/projectGallery';
import share from './components/share';
import relatedProjects from './components/relatedProjects';
import contrastInit from './components/contrastInit';
import contrastingText from './components/contrastingText';
import projectShuffle from './components/projectShuffle';
import stockists from './components/stockists';
import ajaxStickyBasket from './components/ajaxStickyBasket';
import ProductDisplay from './components/productDisplay';
import addToCart from './components/addToCart';
import videoPLYR from './components/videoPLYR';
import categoryDisplayNav from './components/categoryDisplayNav';
import favourite from './components/favourite';
import sidebarAccordion from './components/sidebarAccordion';
import Shipping from './components/shipping';

$(function() {
    'use strict';
    Eskimo.init();
    Menu.init();
    Addressbook.init(); 
    Checkbox.init();
    Copy.init();
    Dropkick.init();
    Fadedelay.init();
    Form.init();
    Matchheight.init();
    Order.init();
    ProgressBar.init();
    TradeAccount.init();
    TradeOrdersLink.init();
    Legacy.init();
    EskimoCheckout.init(); 
    Featured.init();
    Shipping.init();
});